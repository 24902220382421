import React, { useEffect } from 'react';
import Contenido from '../../../layout/Contenido';
import TablaPedidos from '../../tablas/TablaPedidos';
import { useDispatch, useSelector } from 'react-redux';
import { grey } from '@material-ui/core/colors';
import { WATCH_PARALIZADOS } from '../../../redux/actionTypes';

export default function Paralizados() {
	const dispatch = useDispatch();
	let {
		fetching: fetchingParalizados,
		ultimaActualizacion: ultimaActualizacionParalizados,
		paralizados,
	} = useSelector((content) => content.Paralizados);

	useEffect(() => {
		dispatch({ type: WATCH_PARALIZADOS });
	}, [dispatch]);

	return (
		<Contenido title='Pedidos bloqueados y paralizados' color={grey[600]}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				<TablaPedidos
					loading={
						fetchingParalizados && ultimaActualizacionParalizados === null
					}
					pedidos={paralizados}
					showBloquear={true}
					showPreparar={false}
				/>
			</div>
		</Contenido>
	);
}
