import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import DialogContent from '@material-ui/core/DialogContent';

export default function DbStatusOverlay() {
	const { dbSabinaStatus } = useSelector((content) => content.Status);
	const onFailSystem = !dbSabinaStatus;

	// return (
	// 	<Snackbar open={onFailSystem}>
	// 		<Alert severity={'error'} variant={'filled'}>
	// 			Imposible conectar con la base de datos de Sabina
	// 		</Alert>
	// 	</Snackbar>
	// );

	return (
		<Dialog open={onFailSystem} fullWidth scroll='paper'>
			<DialogTitle>Información del Sistema</DialogTitle>
			<DialogContent dividers style={{ minHeight: '8rem' }}>
				{!dbSabinaStatus && (
					<Alert severity='error'>
						Imposible conectar con la base de datos de Sabina
					</Alert>
				)}
			</DialogContent>
		</Dialog>
	);
}
