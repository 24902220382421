import React from 'react';
import { AppBar, Badge, Switch, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	toolbarAppBar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		// padding: theme.spacing(3),
		// width: `calc(100% - ${drawerWidth}px)`,
		width: `100%`,
		height: 'calc(100% - 64px)',
	},
}));

export default function Contenido({
	title = null,
	children,
	color = blue[700],
	acciones = '',
	showGroupSwitch = false,
	groupedAmount = 0,
	defaultGroupMode = false,
	onGroupSwitchChange = (selected) => null,
}) {
	const classes = useStyles();

	return (
		<div id='contenido'>
			{title !== null && (
				<AppBar
					position='fixed'
					className={classes.appBar}
					style={{ backgroundColor: color }}>
					<Toolbar className={classes.toolbarAppBar}>
						<Typography variant='h6' noWrap>
							{title}
						</Typography>
						{showGroupSwitch && (
							<div
								style={{
									marginLeft: '2rem',
									marginRight: 'auto',
								}}>
								<Switch
									defaultChecked={defaultGroupMode}
									color={'default'}
									onChange={(event) =>
										onGroupSwitchChange(event.target.checked)
									}
								/>{' '}
								<Badge
									badgeContent={groupedAmount}
									color='secondary'
									invisible={groupedAmount === 0}
									style={{ paddingRight: '0.5rem' }}>
									Agrupados
								</Badge>
							</div>
						)}
						<div>{acciones}</div>
					</Toolbar>
				</AppBar>
			)}

			<main className={classes.content}>
				<div className={classes.toolbar}></div>
				{children}
			</main>
		</div>
	);
}
