import * as React from 'react';
import {
	esPedidoBloqueado,
	esPedidoParalizado,
	getUnidadesPendientes,
} from '../utils';
import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';

export default function EstadoDetalleProducto({ articulo, pedido }) {
	const unidadesPendientes = getUnidadesPendientes(articulo);
	const palabraFaltan = unidadesPendientes > 1 ? 'Faltan' : 'Falta';

	if (esPedidoBloqueado(pedido) || esPedidoParalizado(pedido)) {
		return ' ';
	}

	if (unidadesPendientes > 0) {
		return (
			<Typography style={{ color: red[700] }}>
				{palabraFaltan} {unidadesPendientes}
			</Typography>
		);
	}

	return <Typography style={{ color: green[700] }}>Completo</Typography>;
}
