import { combineReducers } from 'redux';

import User from './user/reducer';
import Pedidos from './pedidos/reducer';
import Paralizados from './paralizados/reducer';
import Facturas from './facturas/reducer';
import Notifications from './notifications/reducer';
import StatusReducer from './status/status.reducer';

export default combineReducers({
	User,
	Pedidos,
	Paralizados,
	Facturas,
	Notifications,
	Status: StatusReducer,
});
