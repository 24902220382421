import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import { useDispatch, useSelector } from 'react-redux';
import { WATCH_USER } from '../../../redux/actionTypes';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import AuthService from '../../../services/auth.service';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},

	root: {
		maxWidth: 500,
		marginTop: theme.spacing(6),
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
		marginLeft: '4rem',
		marginRight: '4rem',
	},
}));

const Login = () => {
	const dispatch = useDispatch();
	const { fetching, user } = useSelector((content) => content.User);

	const classes = useStyles();

	const [valoresFormularios, setValoresFormularios] = useState({
		usuario: '',
		password: '',
	});
	const [showError, setShowError] = useState(false);

	const handleChange = (e) => {
		setShowError(false);
		const { name, value } = e.target;
		const valoresFormulariosCopy = { ...valoresFormularios };
		valoresFormulariosCopy[name] = value;
		setValoresFormularios(valoresFormulariosCopy);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setShowError(false);
		const { usuario, password } = valoresFormularios;
		if (usuario !== '' && password !== '') {
			const success = await AuthService.login(usuario, password);
			if (success) {
				return dispatch({ type: WATCH_USER });
			}
		}
		setShowError(true);
	};

	useEffect(() => {
		dispatch({ type: WATCH_USER });
	}, [dispatch]);

	return (
		<>
			<Container component='main' maxWidth='xs'>
				<CssBaseline />

				<Card className={classes.root}>
					<CardMedia
						className={classes.media}
						image='/sabina.png'
						title='Sabina'
					/>
					<CardContent>
						<form className={classes.form} noValidate>
							<TextField
								className={classes.textfield}
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='usuario'
								label='Usuario'
								name='usuario'
								autoComplete='usuario'
								autoFocus
								onChange={handleChange}
							/>
							<TextField
								className={classes.textfield}
								variant='outlined'
								margin='normal'
								required
								fullWidth
								name='password'
								label='Contraseña'
								type='password'
								id='password'
								autoComplete='current-password'
								onChange={handleChange}
							/>
							{showError && (
								<Typography style={{ color: red[500] }}>
									Usuario o contraseña incorrecto
								</Typography>
							)}
							<Button
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								className={classes.submit}
								onClick={handleSubmit}>
								Acceder
							</Button>
						</form>
					</CardContent>
				</Card>
			</Container>
			{!fetching && user && <Redirect to={`${process.env.PUBLIC_URL}/`} />}
		</>
	);
};

export default Login;
