import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { blue, green, orange, red } from '@material-ui/core/colors';
import {
	esPedidoBloqueado,
	esPedidoCompleto,
	esPedidoEmailBloqueado,
	esPedidoEnPreparacion,
	esPedidoParalizado,
} from '../utils';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';

export default function EtiquetaEstadoPedido({ pedido }) {
	// return <><DoneAllIcon style={{ color: blue[500], marginRight: '0.5rem' }} /><Typography style={{ color: green[700] }}>Facturado</Typography></>

	if (esPedidoBloqueado(pedido)) {
		if (esPedidoEmailBloqueado(pedido)) {
			return (
				<Typography
					style={{
						color: red[700],
						lineHeight: '20px',
						fontSize: '15px',
					}}>
					Bloqueado
					<br />
					{moment.unix(pedido.UNIXDATE).format('DD/MM/YYYY HH:mm')}
				</Typography>
			);
		}

		return (
			<Typography
				style={{
					color: red[700],
					lineHeight: '20px',
					fontSize: '15px',
				}}>
				Bloqueado
				<br />
				{moment(pedido.LOCK.FECHA).format('DD/MM/YYYY HH:mm')}
			</Typography>
		);
	}

	if (esPedidoParalizado(pedido)) {
		return (
			<Typography style={{ color: orange[800] }}>Pedido paralizado</Typography>
		);
	}

	if (esPedidoEnPreparacion(pedido)) {
		if (esPedidoCompleto(pedido)) {
			return (
				<>
					<PlayArrowIcon
						style={{
							color: blue[400],
							marginRight: '0.5rem',
						}}
					/>
					<Typography style={{ color: blue[700] }}>Preparando...</Typography>
				</>
			);
		} else {
			return (
				<>
					<PlayArrowIcon
						style={{
							color: red[400],
							marginRight: '0.5rem',
						}}
					/>
					<Typography style={{ color: red[700] }}>Incompleto...</Typography>
				</>
			);
		}
	}

	if (esPedidoCompleto(pedido)) {
		return (
			<>
				<DoneIcon
					style={{
						color: green[700],
						marginRight: '0.5rem',
					}}
				/>
				<Typography style={{ color: green[700] }}>
					Listo para preparar
				</Typography>
			</>
		);
	}

	return <Typography style={{ color: red[700] }}>Incompleto</Typography>;
}
