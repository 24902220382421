import * as React from 'react';
import Button from '@material-ui/core/Button';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DialogoDetallesPrepararPedido from './DialogoDetallesPrepararPedido';

export default function LinkDetallesPrepararPedido({ pedido }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const { ID, retraso48h } = pedido;

	return (
		<div>
			<Button
				color='primary'
				onClick={handleClickOpen}
				endIcon={retraso48h ? <AccessAlarmIcon /> : ''}>
				{ID}
			</Button>
			<DialogoDetallesPrepararPedido
				pedido={pedido}
				open={open}
				handleClose={handleClose}
			/>
		</div>
	);
}
