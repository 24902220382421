export default function MarketPlaceImage({ order }) {
	if (!order.MARKETPLACE) {
		return '';
	}

	let src = null;
	switch (order.MARKETPLACE.toUpperCase()) {
		case 'GUANXE':
			src = '/marketplace/logoGuanxe.png';
			break;
		case 'ALIEXPRESS':
			src = '/marketplace/logoAliExpress.png';
			break;
		case 'MIRAVIA':
			src = '/marketplace/logoMiravia.png';
			break;
		case 'AMAZON':
			src = '/marketplace/logoAmazon.png';
			break;
		default:
			break;
	}
	if (!src) {
		return order.MARKETPLACE;
	}
	return <img alt={order.MARKETPLACE} src={src} style={{ height: 25 }} />;
}
