import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import IconoPedidosTiendaProducto from '../iconos/IconoPedidosTiendaProducto';
import IconoLaserProducto from '../iconos/IconoLaserProducto';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PanToolIcon from '@material-ui/icons/PanTool';
import { blue, grey } from '@material-ui/core/colors';
import EstadoDetalleProducto from '../EstadoDetalleProducto';
import IconoSinStock from '../iconos/IconoSinStock';
import {
	esProductoCrossSelling,
	getPedidosATiendaActivos,
	getPedidosATiendaFuturos,
	getTraspasosFuturos,
} from '../../utils';
import IconFutureRequests from '../iconos/IconFutureRequests';

export default function TablaProductos({ pedido }) {
	const columns = [
		{
			sortable: false,
			field: 'PRODUCT_ID',
			headerName: 'ID',
			width: 70,
		},
		{
			sortable: false,
			field: 'HAS_LASER_ENGRAVINGS',
			headerName: 'Laser',
			renderHeader: () => <FlashOnIcon />,
			renderCell: ({ value: HAS_LASER_ENGRAVINGS, row: detail }) => {
				if (HAS_LASER_ENGRAVINGS) {
					return <IconoLaserProducto detail={detail} order={pedido} />;
				}
				return <span />;
			},
			width: 50,
		},
		{
			sortable: false,
			field: 'DESCRIPTION',
			headerName: 'PRODUCTO',
			flex: 1,
			renderCell: ({ value, row: detail }) => {
				if (detail.IS_LASER_ENGRAVING) {
					// laser
					return (
						<>
							<FlashOnIcon
								style={{
									marginRight: '0.5rem',
									color: blue[700],
								}}
							/>
							<strong style={{ color: blue[900] }}>{value}</strong>
						</>
					);
				}

				if (detail.IS_GIFT_WRAP) {
					// regalo
					return (
						<>
							<CardGiftcardIcon
								style={{
									marginRight: '1rem',
									color: blue[700],
								}}
							/>
							<strong style={{ color: blue[900] }}>{value}</strong>
						</>
					);
				}

				if (detail.IS_PARALYZED) {
					// paralizado
					return (
						<>
							<PanToolIcon
								style={{
									marginRight: '1rem',
									color: grey[700],
								}}
							/>
							<strong>{value}</strong>
						</>
					);
				}

				return value;
			},
		},
		{
			sortable: false,
			field: 'AMOUNT',
			headerName: 'Cantidad',
			width: 100,
			renderCell: ({ value }) => {
				return (
					<div
						style={{
							textAlign: 'center',
							width: '100%',
						}}>
						{value}
					</div>
				);
			},
		},
		{
			sortable: false,
			field: 'REQUESTS',
			renderHeader: () => <LocalShippingIcon />,
			renderCell: ({ value: requests, row: detail }) => {
				if (esProductoCrossSelling(detail)) {
					return <IconoSinStock crossSelling />;
				}

				if (detail.SOLD_OUT_AMOUNT > 0) {
					return <IconoSinStock />;
				}

				const pedidosTiendaActivos = getPedidosATiendaActivos(pedido);
				const pedidosATiendaFuturos = getPedidosATiendaFuturos(pedido);
				const traspasosFuturos = getTraspasosFuturos(pedido);

				if (
					pedidosTiendaActivos.some(
						(r) => r.PRODUCT_ID.toString() === detail.PRODUCT_ID.toString()
					) &&
					!traspasosFuturos.some(
						(r) => r.PRODUCT_ID.toString() === detail.PRODUCT_ID.toString()
					)
				) {
					return (
						<IconoPedidosTiendaProducto pedido={pedido} articulo={detail} />
					);
				}

				if (
					pedidosATiendaFuturos.some(
						(r) => r.PRODUCT_ID.toString() === detail.PRODUCT_ID.toString()
					) ||
					traspasosFuturos.some(
						(r) => r.PRODUCT_ID.toString() === detail.PRODUCT_ID.toString()
					)
				) {
					return (
						<IconFutureRequests
							order={pedido}
							resumeType={'total'}
							productId={detail.PRODUCT_ID}
						/>
					);
				}

				return <span />;
			},
			width: 50,
		},
		{
			sortable: false,
			field: 'estado',
			headerName: 'Estado',
			renderCell: ({ row: articulo }) => {
				const detalle = pedido.DETAILS.find(
					(d) => d.PRODUCT_ID.toString() === articulo.PRODUCT_ID.toString()
				);
				return <EstadoDetalleProducto articulo={detalle} pedido={pedido} />;
			},
			width: 150,
		},
		{
			sortable: false,
			field: 'UNIT_PRICE',
			headerName: 'Precio',
			width: 150,
		},
		{
			sortable: false,
			field: 'TOTAL_PRICE',
			headerName: 'Total',
			width: 150,
		},
	];

	const { DETAILS } = pedido;

	const detallesOrdenados = DETAILS.sort((a, b) => {
		if (a.SOLD_OUT_AMOUNT !== b.SOLD_OUT_AMOUNT) {
			return b.SOLD_OUT_AMOUNT - a.SOLD_OUT_AMOUNT;
		}
		if (a.INCOMPLETE_AMOUNT !== b.INCOMPLETE_AMOUNT) {
			return b.INCOMPLETE_AMOUNT - a.INCOMPLETE_AMOUNT;
		}
		return a.PRODUCT_ID - b.PRODUCT_ID;
	}).map((d, i) => ({ id: i, ...d }));

	return (
		<DataGrid
			rows={detallesOrdenados}
			columns={columns}
			pageSize={100}
			hideFooter
			hideFooterPagination
			hideFooterRowCount
			disableColumnMenu
			disableSelectionOnClick
		/>
	);
}
