import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { green, grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';

export default function IconoAyudaExtraviado() {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<HelpIcon onClick={handleClickOpen} style={{ fontSize: '1rem' }} />
			<Dialog
				open={open}
				onClose={handleClose}
				scroll='paper'
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'>
				<DialogTitle>Pedido a tienda Extraviado</DialogTitle>
				<DialogContent dividers>
					<Alert severity='error' style={{ marginBottom: '2rem' }}>
						Es posible que alguno de los articulos pedidos a tienda no se halla
						recibido
					</Alert>
					<DialogContentText>
						<Typography
							variant='h6'
							component='div'
							style={{ color: green[700] }}>
							Como solucionarlo
						</Typography>
						<Typography variant='body1' style={{ color: green[700] }}>
							Cancele el pedido a tienda extraviado.
						</Typography>
						<Typography variant='body1' style={{ marginBottom: '2rem' }}>
							El sistema volvera a incluirlo en el calculo de unidades
							pendientes en el proximo pedido a tienda.
						</Typography>

						<Typography
							variant='h6'
							component='div'
							style={{ color: grey[900] }}>
							¿Como se sabe que un pedido a tienda se ha extraviado?
						</Typography>
						<Typography variant='body1' style={{ marginBottom: '2rem' }}>
							Se trata de una estimación calculada.
							<br />
							Un pedido a tienda se considera extraviado si otros productos de
							ese mismo pedido ya han sido recibidos, mientras este sigue
							pendiente.
						</Typography>

						<Typography
							variant='h6'
							component='div'
							style={{ color: grey[900] }}>
							¿Que significa que un pedido a tienda se ha extraviado?
						</Typography>
						<Typography variant='body1' style={{ marginBottom: '2rem' }}>
							Durante la preparacion de los pedidos por parte de las tiendas
							pueden suceder varias incidencias tales como rotura de stock o
							error en la preparacion del mismo.
						</Typography>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
}
