import { all } from 'redux-saga/effects';
import { watcherUser } from './user';
import { watcherPedidos } from './pedidos';
import { watcherParalizados } from './paralizados';
import { watcherFacturas } from './facturas';
import { watcherNotifications } from './notifications';

export default function* rootSagas() {
	yield all([
		watcherUser(),
		watcherPedidos(),
		watcherParalizados(),
		watcherFacturas(),
		watcherNotifications(),
	]);
}
