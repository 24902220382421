import React from 'react';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { blue, red } from '@material-ui/core/colors';
import { Badge, Box } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import GiftCardActionButton from '../botones/GiftCardActionButton';

function Dialogo({ title, open, onClose, children, actions }) {
	return (
		<Dialog fullWidth scroll='paper' open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers style={{ minHeight: '8rem' }}>
				{children}
			</DialogContent>
			{actions && <DialogActions>{actions}</DialogActions>}
		</Dialog>
	);
}

export default function IconoRegalo({
	pedido: {
		ID,
		GIFT_WRAP: { MENSAJE },
		SHIPMENT: { NOMBRECOMERCIAL = '' },
	},
}) {
	const [open, setOpen] = React.useState(false);
	const isValidMessage = MENSAJE && MENSAJE !== '';

	const body = (
		<Box>
			<strong>ID Pedido:</strong> {ID}
			<br />
			<strong>Cliente:</strong> {NOMBRECOMERCIAL}
			<br />
			<br />
			{isValidMessage && (
				<>
					<strong>Mensaje de regalo:</strong>
					<br />
					{MENSAJE}
				</>
			)}
			{MENSAJE === '' && (
				<strong style={{ color: red[500] }}>
					Envoltorio de regalo sin mensaje
				</strong>
			)}
			{MENSAJE === null && (
				<strong style={{ color: red[500] }}>
					No se pudo obtener el mensaje para la tarjeta regalo, por favor, ponte
					en contacto con el administrador
				</strong>
			)}
		</Box>
	);

	return (
		<>
			<Badge
				color='secondary'
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				badgeContent={MENSAJE !== '' ? 1 : 0}>
				<CardGiftcardIcon
					onClick={() => setOpen(true)}
					style={{ color: blue[700] }}
				/>
			</Badge>

			<Dialogo
				title={'Envoltorio de Regalo'}
				open={open}
				onClose={() => setOpen(false)}
				actions={
					<>
						<GiftCardActionButton
							message={MENSAJE}
							type={'download'}
							disabled={!isValidMessage}
							variant={'contained'}
						/>
					</>
				}>
				{body}
			</Dialogo>
		</>
	);
}
