import React from 'react';
import Contenido from '../../../layout/Contenido';
import { useSelector } from 'react-redux';
import TablaPedidos from '../../tablas/TablaPedidos';
import { getPedidosCompletosEstadosUnidosBloqueados } from '../../../utils';
import { red } from '@material-ui/core/colors';
import { PRECIO_BLOQUEO_PEDIDOS_EEUU } from '../../../constants';

export default function Estadosunidosbloqueados() {
	const { fetching, ultimaActualizacion, pedidos } = useSelector(
		(content) => content.Pedidos
	);

	return (
		<Contenido
			title={
				'Pedidos de Estados Unidos Bloqueados por precio mayor que ' +
				PRECIO_BLOQUEO_PEDIDOS_EEUU
			}
			color={red[600]}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				<TablaPedidos
					loading={fetching && ultimaActualizacion === null}
					pedidos={getPedidosCompletosEstadosUnidosBloqueados(pedidos)}
					showBloquear={true}
					showPreparar={true}
					showPedidosATienda={true}
				/>
			</div>
		</Contenido>
	);
}
