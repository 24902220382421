import { FETCH_USER, SET_USER_ONLINE } from '../actionTypes';

const initialState = {
	online: true,
	fetching: true,
	user: null,
	esAdmin: false,
	showTraspasos: false,
};

const User = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_USER:
			const user = payload.user || state.user;
			return {
				...state,
				...payload,
				esAdmin: user ? user.admin === 1 : false,
				showTraspasos: user ? user.equipo === 'COMPLEMENTOS' : false,
			};
		case SET_USER_ONLINE:
			return {
				...state,
				online: payload.online,
			};

		default:
			return {
				...state,
			};
	}
};

export default User;
