import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	rootActive: {
		width: 220,
		height: 230,
		margin: '1rem',
		paddingTop: '1rem',
		opacity: 1,
	},
	rootInactive: {
		width: 220,
		height: 230,
		margin: '1rem',
		paddingTop: '1rem',
		opacity: 0.2,
	},
	media: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundSize: 'contain',
		height: '50px',
		margin: '1.5rem',
	},
	titulo: {
		textAlign: 'center',
	},
});

export default function SeccionCard({
	children,
	active = true,
	titulo,
	imagen = null,
	icon,
	to,
	loading = false,
}) {
	const classes = useStyles();

	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				<Link to={to} ref={ref} {...itemProps} />
			)),
		[to]
	);

	return (
		<Card className={active ? classes.rootActive : classes.rootInactive}>
			<CardActionArea to={to} component={renderLink}>
				{icon && <div className={classes.media}>{icon}</div>}

				{imagen && (
					<CardMedia className={classes.media} image={imagen} title={titulo} />
				)}
				<CardContent>
					<Typography
						gutterBottom
						variant='h6'
						component='h6'
						className={classes.titulo}>
						{titulo}
					</Typography>
					{loading && <div style={{ textAlign: 'center' }}>Cargando...</div>}
					{children}
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
