import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fetchNuevaFacturaApi } from '../api/common.services';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},

	root: {
		maxWidth: 500,
		marginTop: theme.spacing(6),
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
		marginLeft: '4rem',
		marginRight: '4rem',
	},
}));

export default function DialogoCrearFactura({ handleConfirmar = () => null }) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [valoresFormularios, setValoresFormularios] = useState({
		pedido: '',
		factura: '',
	});
	const [showError, setShowError] = useState(false);

	const handleChange = (e) => {
		setShowError(false);
		const { name, value } = e.target;
		const valoresFormulariosCopy = { ...valoresFormularios };
		valoresFormulariosCopy[name] = value;
		setValoresFormularios(valoresFormulariosCopy);
	};

	const abrir = () => {
		setShowError(false);
		setValoresFormularios({
			pedido: '',
			factura: '',
		});
		setOpen(true);
	};

	const cerrar = () => {
		setOpen(false);
	};

	const handleConfirm = async () => {
		setShowError(false);
		const { pedido, factura } = valoresFormularios;
		if (pedido !== '' && factura !== '') {
			const { success } = await fetchNuevaFacturaApi({
				SUPEDIDO: pedido,
				NUMFAC: factura,
			});
			if (success) {
				handleConfirmar();
				return cerrar();
			}
		}
		setShowError(true);
	};

	return (
		<div>
			<Button
				startIcon={<AddIcon />}
				variant='contained'
				color='secondary'
				onClick={abrir}>
				Crear nueva factura
			</Button>
			<Dialog
				open={open}
				onClose={cerrar}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Crear nueva factura</DialogTitle>
				<DialogContent>
					<form className={classes.form} noValidate>
						<TextField
							className={classes.textfield}
							variant='outlined'
							margin='normal'
							required
							fullWidth
							id='idPedido'
							label='Pedido'
							name='pedido'
							autoComplete='pedido'
							autoFocus
							onChange={handleChange}
						/>
						<TextField
							className={classes.textfield}
							variant='outlined'
							margin='normal'
							required
							fullWidth
							name='factura'
							label='Número de factura'
							type='factura'
							id='factura'
							autoComplete='current-password'
							onChange={handleChange}
						/>
						{showError && (
							<Typography style={{ color: red[500] }}>
								Todos los campos son obligatorios
							</Typography>
						)}
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={cerrar} autoFocus>
						Cancelar
					</Button>
					<Button onClick={handleConfirm} color='primary'>
						Crear factura
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
