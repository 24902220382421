import React from 'react';
import Contenido from '../../../layout/Contenido';
import { useSelector } from 'react-redux';
import TablaPedidos from '../../tablas/TablaPedidos';
import { getPedidosGuanxe } from '../../../utils';
import { blue } from '@material-ui/core/colors';

export default function Guanxe() {
	const { fetching, ultimaActualizacion, pedidos } = useSelector(
		(content) => content.Pedidos
	);

	return (
		<Contenido title='Pedidos de Guanxe' color={blue[700]}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				<TablaPedidos
					loading={fetching && ultimaActualizacion === null}
					pedidos={getPedidosGuanxe(pedidos)}
					showBloquear={true}
					showPreparar={true}
					showPedidosATienda={true}
				/>
			</div>
		</Contenido>
	);
}
