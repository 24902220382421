import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
	esPedidoMarketplace,
	getPdfInvoiceUrl,
	getPdfLaserUrl,
	getPedidosATiendaActivos,
} from '../utils';
import BarCode from './Barcode';
import Divider from '@material-ui/core/Divider';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { blue } from '@material-ui/core/colors';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { Badge } from '@material-ui/core';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { useSelector } from 'react-redux';
import PrintButton from './botones/PrintButton';
import GiftCardActionButton from './botones/GiftCardActionButton';
import DialogActions from '@material-ui/core/DialogActions';
import CarrierListImage from './carrier/CarrierListImage';
import MarketPlaceImage from './marketplace/MarketPlaceImage';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant='h6'>{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

export default function DialogoDetallesPrepararPedido({
	open,
	handleClose,
	pedido,
}) {
	const { user } = useSelector((content) => content.User);
	const { CODCLIENTE, NOMBRECOMERCIAL, EMAIL, PAIS, CODPOSTAL } =
		pedido.SHIPMENT;
	const { AMOUNT } = pedido.DETAILS_RESUME;

	const pedidosATienda = getPedidosATiendaActivos(pedido);
	const pedidosATiendaResumen = {};
	pedidosATienda.forEach((r) => {
		if (!pedidosATiendaResumen[r.PRODUCT_ID]) {
			let name = r.PRODUCT_ID;
			const articulo = pedido.DETAILS.find(
				(d) => d.PRODUCT_ID === r.PRODUCT_ID
			);
			if (articulo) {
				name = r.PRODUCT_ID + ' - ' + articulo.DESCRIPTION;
			}
			pedidosATiendaResumen[r.PRODUCT_ID] = pedidosATiendaResumen[
				r.PRODUCT_ID
			] || {
				name,
				amount: 0,
			};
		}

		pedidosATiendaResumen[r.PRODUCT_ID].amount += r.AMOUNT;
	});

	return (
		<Dialog fullWidth onClose={handleClose} open={open}>
			<DialogTitle onClose={handleClose}>
				Detalles de pedido en preparación
			</DialogTitle>
			<DialogContent dividers>
				<div style={{ width: '35rem' }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'flex-start',
							paddingBottom: '1rem',
						}}>
						<div
							style={{
								paddingLeft: '1rem',
								paddingRight: '1rem',
							}}>
							<BarCode textobarcode={CODCLIENTE} />
						</div>
						<div style={{ paddingTop: '1rem' }}>
							{!esPedidoMarketplace(pedido) && (
								<>
									<Typography variant='h5'>{NOMBRECOMERCIAL}</Typography>
									<Typography variant='body1' gutterBottom>
										{EMAIL}
									</Typography>
								</>
							)}
							{esPedidoMarketplace(pedido) && (
								<MarketPlaceImage order={pedido} />
							)}
							<Typography variant='body1' gutterBottom>
								{CODPOSTAL}, {PAIS}
							</Typography>
							<CarrierListImage order={pedido} />
						</div>
					</div>

					<div>
						<Divider />
						<div
							style={{
								padding: '1rem',
								marginLeft: '1rem',
							}}>
							<Typography variant='body1'>
								Este pedido tiene {AMOUNT} unidades
							</Typography>
						</div>
					</div>

					{pedidosATienda.length > 0 &&
						Object.values(pedidosATiendaResumen).map((d, i) => {
							return (
								<div key={i}>
									<Divider />
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											padding: '1rem',
											marginLeft: '1rem',
										}}>
										<Badge
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											badgeContent={d.amount}>
											<LocalShippingIcon
												style={{
													fontSize: 30,
													color: blue[700],
												}}
											/>
										</Badge>
										<Typography variant='body1' style={{ marginLeft: '1rem' }}>
											{d.name}
										</Typography>
									</div>
								</div>
							);
						})}

					{pedido.HAS_GIFT_WRAP && (
						<div>
							<Divider />
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '1rem',
									marginLeft: '1rem',
								}}>
								<CardGiftcardIcon
									style={{
										fontSize: 30,
										marginRight: '0.5rem',
										color: blue[700],
									}}
								/>
								<GiftCardActionButton
									message={pedido.GIFT_WRAP.MENSAJE}
									type={'download'}
									size={'small'}
									variant={'contained'}
								/>
								<Typography variant='body1' style={{ marginLeft: '1rem' }}>
									Envoltorio de regalo
								</Typography>
							</div>
						</div>
					)}

					{pedido.HAS_LASER_ENGRAVINGS && (
						<div>
							<Divider />
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '1rem',
									marginLeft: '1rem',
								}}>
								<FlashOnIcon
									style={{
										fontSize: 30,
										marginRight: '0.5rem',
										color: blue[700],
									}}
								/>
								<PrintButton url={getPdfLaserUrl(pedido.ID)} size={'small'} />
								<Typography variant='body1' style={{ marginLeft: '1rem' }}>
									Contiene articulos con grabado laser
								</Typography>
							</div>
						</div>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<PrintButton url={getPdfInvoiceUrl(pedido.ID, user.equipo)}>
					Imprimir factura
				</PrintButton>
			</DialogActions>
		</Dialog>
	);
}
