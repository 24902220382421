import axios from 'axios';

const AuthService = {
	login: async (user, password) => {
		return await axios
			.post(`/api/login`, {
				user,
				password,
			})
			.then((response) => {
				const { success, data: { token } = {} } = response;
				if (success) {
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
					localStorage.setItem('token', token);
				}
				return success;
			})
			.catch(() => false);
	},

	logout: () => {
		delete axios.defaults.headers.common['Authorization'];
		localStorage.removeItem('token');
		return true;
	},
};

export default AuthService;
