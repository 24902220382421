import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { fetchEliminarFacturaApi } from '../../api/common.services';
import { useDispatch } from 'react-redux';
import { WATCH_FACTURAS } from '../../redux/actionTypes';
import DialogoConfirmacion from '../DialogoConfirmacion';

export default function TablaFacturas({ loading = false, facturas = [] }) {
	const dispatch = useDispatch();

	const eliminarFactura = async ({ SUPEDIDO }) => {
		const { success } = await fetchEliminarFacturaApi({ SUPEDIDO });
		if (success) {
			dispatch({ type: WATCH_FACTURAS });
		}
	};

	const columns = [
		{
			sortable: false,
			field: 'SUPEDIDO',
			headerName: 'Pedido',
			width: 150,
		},
		{
			sortable: false,
			field: 'NUMFAC',
			headerName: 'Numero de factura',
			width: 200,
			flex: 1,
		},
		{
			sortable: false,
			field: 'acciones',
			headerName: 'Acciones',
			width: 140,
			renderCell: ({ row: factura }) => {
				return (
					<DialogoConfirmacion
						title='Eliminar número de factura'
						handleConfirmar={() => eliminarFactura(factura)}
						textButtonConfirmar='Eliminar'
						textButtonOpen='Eliminar'>
						<div style={{ width: '18rem' }}>
							Pedido: {factura.SUPEDIDO}
							<br />
							Numero de factura: {factura.NUMFAC}
						</div>
					</DialogoConfirmacion>
				);
			},
		},
	];

	return (
		<DataGrid
			rows={facturas}
			columns={columns}
			pageSize={100}
			loading={loading}
			disableColumnMenu
			disableSelectionOnClick
		/>
	);
}
