import React, { useState } from 'react';
import Contenido from '../../../layout/Contenido';
import { useSelector } from 'react-redux';
import TablaPedidos from '../../tablas/TablaPedidos';
import {
	agruparPedidos,
	esPedidoEnPreparacion,
	esPedidoMiravia,
	getPedidosCarrier,
} from '../../../utils';
import { green } from '@material-ui/core/colors';
import TablaPedidosAgrupados from '../../tablas/TablaPedidosAgrupados';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';

export default function PedidosCarrier() {
	let { carrierName } = useParams();
	let carriers = [carrierName];
	let title = '';
	let src = '';
	switch (carrierName) {
		case 'seur':
		case 'seur-europa':
		case 'seur-canarias':
			title = 'Seur';
			src = '/carrier/logoSeur.png';
			carriers.push('seur', 'seur-europa', 'seur-canarias');
			break;
		case 'gls':
			title = 'GLS';
			src = '/carrier/logoGLS.png';
			break;
		case 'tipsa':
			title = 'Tipsa';
			src = '/carrier/logoTipsa.png';
			break;
		case 'dhl':
			title = 'DHL';
			src = '/carrier/logoDHL.png';
			break;
		case 'dhl-europa':
			title = 'DHL';
			src = '/carrier/logoDHLEuropa.png';
			break;
		case 'pickup-point':
			title = 'Pickup Point';
			src = '/carrier/logoPickupPoint.png';
			break;
		case 'no-carrier':
			title = 'Sin transportista';
			src = '';
			carriers = [''];
			break;
		default:
			title = 'Transportista no encontrado';
			src = '';
			carriers = [''];
			break;
	}

	const { fetching, ultimaActualizacion, pedidos } = useSelector(
		(content) => content.Pedidos
	);

	// const { esAdmin } = useSelector((content) => content.User);

	const [groupMode, setGroupMode] = useState(false);
	const pedidosCarrier = getPedidosCarrier(pedidos, carriers);
	const groupedAmount = agruparPedidos(pedidosCarrier).filter(
		(g) => g.orders.length > 1
	).length;

	// Los pedidos de Miravia tienen prioridad de preparacion
	pedidosCarrier.sort((a, b) => {
		if (esPedidoMiravia(a) && !esPedidoMiravia(b)) {
			return -1; // a debe ir antes que b
		}
		if (!esPedidoMiravia(a) && esPedidoMiravia(b)) {
			return 1; // b debe ir antes que a
		}
		// Si ambos son de Miravia o ambos no lo son, se mantiene su orden
		return 0;
	});

	return (
		<Contenido
			title={
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					{src !== '' && (
						<img
							alt={title}
							src={src}
							style={{
								height: 25,
								border: '1px solid #333',
								marginRight: '1rem',
							}}
						/>
					)}
					{title}
				</Box>
			}
			color={green[500]}
			showGroupSwitch={true}
			groupedAmount={groupedAmount}
			defaultGroupMode={false}
			onGroupSwitchChange={(selected) => setGroupMode(selected)}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				{!groupMode && (
					<TablaPedidos
						loading={fetching && ultimaActualizacion === null}
						pedidos={pedidosCarrier}
						showBloquear={true}
						showPreparar={true}
						showPedidosATienda={true}
					/>
				)}
				{groupMode && (
					<TablaPedidosAgrupados
						loading={fetching && ultimaActualizacion === null}
						pedidos={pedidosCarrier.filter(
							(order) => !esPedidoEnPreparacion(order)
						)}
						showBloquear={true}
						showPreparar={true}
						showPedidosATienda={true}
					/>
				)}
			</div>
		</Contenido>
	);
}
