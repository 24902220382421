import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import RepeatIcon from '@material-ui/icons/Repeat';
import Typography from '@material-ui/core/Typography';
import { blue, grey, red } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Chip from '@material-ui/core/Chip';

export default function IconoSinStock({ crossSelling = false }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<WarningIcon
				onClick={handleClickOpen}
				style={{ color: crossSelling ? blue[500] : red[500] }}
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll='paper'
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'>
				{crossSelling && (
					<DialogTitle>
						Productos de venta cruzada{' '}
						<Chip
							label='BETA'
							style={{
								color: '#fff',
								backgroundColor: '#ed6c02',
								marginLeft: '0.5rem',
								height: '25px',
							}}
						/>
					</DialogTitle>
				)}
				{!crossSelling && <DialogTitle>Productos Sin Stock</DialogTitle>}
				<DialogContent dividers>
					{crossSelling && (
						<>
							<Alert
								severity='info'
								icon={<RepeatIcon fontSize='inherit' />}
								style={{ marginBottom: '1rem' }}>
								<AlertTitle>Cross Selling</AlertTitle>Es posible que este
								producto se encuentre disponible en el almacén de venta cruzada
							</Alert>
							<DialogContentText component='div'>
								<Typography
									variant='h6'
									component='div'
									style={{ color: grey[900] }}
									gutterBottom>
									¿Como se sabe que un producto es una venta cruzada?
								</Typography>
								<Typography variant='body1' style={{ color: grey[900] }}>
									El reparto de las unidades indica que no se encuentran
									unidades en el almacén local pero si existen existencias
									disponibles en el almacén de venta cruzada.
								</Typography>
							</DialogContentText>
							<Alert
								variant='filled'
								severity='warning'
								icon={false}
								style={{ marginBottom: '1rem' }}>
								<AlertTitle>Funcionalidad en version Beta</AlertTitle>
								Esta funcionaliad se encuentra en fase de pruebas. Es posible
								que el resultado no sea el correcto, el cálculo del stock de los
								almacenes incluye sus almacenes proveedores sin tener en cuenta
								los pedidos a tienda asignados. Esto puede devolver falsos
								positivos.
							</Alert>
						</>
					)}
					{!crossSelling && (
						<>
							<Alert severity='error' style={{ marginBottom: '1rem' }}>
								Alguno de los productos de este pedido se encuentra sin stock
							</Alert>
							<DialogContentText component='div'>
								<Typography
									variant='h6'
									component='div'
									style={{ color: grey[900] }}
									gutterBottom>
									¿Como se sabe que un producto no tiene stock?
								</Typography>
								<Typography variant='body1' style={{ color: red[500] }}>
									El reparto de las unidades arroja un resultado incompletable
									con el stock actual disponible en el almacén asignado.
								</Typography>
							</DialogContentText>
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}
