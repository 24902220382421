import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const StyledAccordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(Accordion);

const StyledAccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(AccordionSummary);

const StyledAccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(AccordionDetails);

export default function BotonImagenesPedidoTienda({
	pedidoATienda,
	handleConfirmar,
	inputSearchRef = () => null,
}) {
	const [open, setOpen] = React.useState(false);
	const [imgFull, setImgFull] = React.useState(false);

	const abrir = () => {
		setOpen(true);
	};

	const cerrar = () => {
		setOpen(false);
		setTimeout(() => {
			inputSearchRef.current.value = '';
			inputSearchRef.current.focus();
		});
	};

	return (
		<div>
			<Button
				variant='contained'
				color='primary'
				onClick={abrir}
				startIcon={<VisibilityOutlinedIcon />}>
				Detalles
			</Button>
			<Dialog
				open={open}
				onClose={cerrar}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>
					{pedidoATienda.DESCRIPCION}
				</DialogTitle>
				<DialogContent>
					<StyledAccordion>
						<StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Códigos de barra</Typography>
						</StyledAccordionSummary>
						<StyledAccordionDetails>
							<List>
								{pedidoATienda.barcodes.map((barcode) => (
									<ListItem key={barcode}>
										<ListItemText
											id='switch-list-label-wifi'
											primary={barcode}
										/>
									</ListItem>
								))}
							</List>
						</StyledAccordionDetails>
					</StyledAccordion>

					<ImageList style={{ marginTop: '0.5rem' }} cols={3}>
						{pedidoATienda.images.map((item, i) => (
							<ImageListItem key={i} cols={1}>
								<img
									src={item}
									alt={pedidoATienda.DESCRIPCION + i}
									onClick={() => setImgFull(item)}
								/>
							</ImageListItem>
						))}
					</ImageList>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>

			<Dialog
				fullScreen
				open={imgFull}
				onClose={cerrar}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>
					{pedidoATienda.DESCRIPCION}
				</DialogTitle>
				<DialogContent onClick={() => setImgFull(false)}>
					<img src={imgFull} alt={pedidoATienda.DESCRIPCION} />
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</div>
	);
}
