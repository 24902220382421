import { call, put, takeLatest } from 'redux-saga/effects';
import { WATCH_USER } from '../actionTypes';
import { fetchUser } from './action';
import AccountService from '../../services/account.service';

function* fetchMeAsyn() {
	yield put(
		fetchUser({
			fetching: true,
		})
	);
	const { success, data } = yield call(AccountService.me);
	yield put(
		fetchUser({
			fetching: false,
			user: success ? data : null,
		})
	);
}

export function* watcherUser() {
	yield takeLatest(WATCH_USER, fetchMeAsyn);
}
