/* USER */
export const WATCH_USER = 'WATCH_USER';
export const FETCH_USER = 'FETCH_USER';
export const SET_USER_ONLINE = 'SET_USER_ONLINE';

/* PEDIDOS */
export const WATCH_PEDIDOS = 'WATCH_PEDIDOS';
export const UPDATE_PEDIDOS = 'UPDATE_PEDIDOS';
export const SET_PREPARACION = 'SET_PREPARACION';
export const BLOQUEAR_PEDIDO = 'BLOQUEAR_PEDIDO';
export const DESBLOQUEAR_PEDIDO = 'DESBLOQUEAR_PEDIDO';
export const CANCELAR_PEDIDO_TIENDA = 'CANCELAR_PEDIDO_TIENDA';
export const ELIMINAR_TRASPASO = 'ELIMINAR_TRASPASO';

/* PEDIDOS PARALIZADOS*/
export const WATCH_PARALIZADOS = 'WATCH_PARALIZADOS';
export const UPDATE_PARALIZADOS = 'FETCH_PARALIZADOS';

/* FACTURAS */
export const WATCH_FACTURAS = 'WATCH_FACTURAS';
export const UPDATE_FACTURAS = 'UPDATE_FACTURAS';

/* NOTIFICATIONS */
export const WATCH_NOTIFICATIONS = 'WATCH_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

/* STATUS */
export const SET_STATUS = 'SET_STATUS';

/* PREPARACIONES */
export const SET_PREPARACIONES = 'SET_PREPARACIONES';
