import React, { useState } from 'react';
import Contenido from '../../../layout/Contenido';
import { useSelector } from 'react-redux';
import TablaPedidos from '../../tablas/TablaPedidos';
import {
	agruparPedidos,
	esPedidoEnPreparacion,
	getPedidosCompletos,
} from '../../../utils';
import { green } from '@material-ui/core/colors';
import TablaPedidosAgrupados from '../../tablas/TablaPedidosAgrupados';

export default function Completos() {
	const { fetching, ultimaActualizacion, pedidos } = useSelector(
		(content) => content.Pedidos
	);

	// const { esAdmin } = useSelector((content) => content.User);

	const [groupMode, setGroupMode] = useState(false);
	const pedidosCompletos = getPedidosCompletos(pedidos);
	const groupedAmount = agruparPedidos(pedidosCompletos).filter(
		(g) => g.orders.length > 1
	).length;

	return (
		<Contenido
			title='Preparación de pedidos'
			color={green[500]}
			showGroupSwitch={true}
			groupedAmount={groupedAmount}
			defaultGroupMode={false}
			onGroupSwitchChange={(selected) => setGroupMode(selected)}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				{!groupMode && (
					<TablaPedidos
						loading={fetching && ultimaActualizacion === null}
						pedidos={pedidosCompletos}
						showBloquear={true}
						showPreparar={true}
						showPedidosATienda={true}
					/>
				)}
				{groupMode && (
					<TablaPedidosAgrupados
						loading={fetching && ultimaActualizacion === null}
						pedidos={pedidosCompletos.filter(
							(order) => !esPedidoEnPreparacion(order)
						)}
						showBloquear={true}
						showPreparar={true}
						showPedidosATienda={true}
					/>
				)}
			</div>
		</Contenido>
	);
}
