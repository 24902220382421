import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import DialogoConfirmacion from '../DialogoConfirmacion';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEliminarTraspasoApi } from '../../api/common.services';
import { ELIMINAR_TRASPASO } from '../../redux/actionTypes';
import { getTraspasosActivos, getTraspasosFuturos } from '../../utils';

export default function TablaTraspasos({ pedido }) {
	const dispatch = useDispatch();

	const { esAdmin } = useSelector((content) => content.User);

	const eliminarTraspaso = async ({ ID }) => {
		const { success } = await fetchEliminarTraspasoApi({ ID });
		if (success) {
			dispatch({
				type: ELIMINAR_TRASPASO,
				payload: {
					ORDER_ID: pedido.ID,
					ID,
				},
			});
		}
	};

	const columns = [
		{
			sortable: false,
			field: 'PRODUCT_ID',
			headerName: 'Producto',
			renderCell: ({ value: PRODUCT_ID }) => {
				const articulo = pedido.DETAILS.find(
					(d) => d.PRODUCT_ID === PRODUCT_ID
				);
				if (articulo) {
					return PRODUCT_ID + ' - ' + articulo.DESCRIPTION;
				}
				return PRODUCT_ID;
			},
			flex: 1,
		},
		{
			sortable: false,
			field: 'AMOUNT',
			renderHeader: () => <SyncAltIcon />,
			renderCell: ({ value }) => {
				return (
					<div
						style={{
							textAlign: 'center',
							width: '100%',
						}}>
						{value}
					</div>
				);
			},
			width: 60,
		},
		{
			sortable: false,
			field: 'UNIXDATE',
			headerName: 'Fecha',
			valueGetter: ({ value: unixdate }) => {
				if (unixdate) {
					return moment.unix(unixdate).format('DD/MM/YYYY HH:mm');
				}
				return 'Próximamente...';
			},
			width: 150,
		},
		{
			sortable: false,
			field: 'accion-eliminar',
			headerName: 'Acciones',
			hide: !esAdmin,
			renderCell: ({ row: traspaso }) => {
				const { ID, UNIXDATE, PRODUCT_ID } = traspaso;
				if (!UNIXDATE) {
					return '';
				}
				return (
					<DialogoConfirmacion
						title='Eliminar traspaso'
						text={
							'¿Estas seguro de eliminar este traspaso? CODARTICULO: ' +
							PRODUCT_ID
						}
						textButtonOpen='Eliminar'
						buttonProps={{ variant: 'outlined' }}
						textButtonConfirmar='Eliminar traspaso'
						textButtonCancelar='Volver'
						handleConfirmar={() => eliminarTraspaso({ ID })}
					/>
				);
			},
			width: 120,
		},
	];

	let traspasos = [
		...getTraspasosActivos(pedido),
		...getTraspasosFuturos(pedido),
	];
	traspasos = traspasos.map((r, i) => ({ id: i, ...r }));

	return (
		<DataGrid
			rows={traspasos}
			columns={columns}
			pageSize={100}
			hideFooter
			hideFooterPagination
			hideFooterRowCount
			disableColumnMenu
			disableSelectionOnClick
		/>
	);
}
