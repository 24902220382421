import Dashboard from '../components/application/dashboard';
import Completos from '../components/application/completos';
import Inglaterra from '../components/application/inglaterra';
import EstadosUnidos from '../components/application/estadosunidos';
import EstadosUnidosBloqueados from '../components/application/estadosunidos/bloqueados';
import Guanxe from '../components/application/guanxe';
import Amazon from '../components/application/amazon';
import AliExpress from '../components/application/aliexpress';
import Miravia from '../components/application/miravia';
import Incompletos from '../components/application/incompletos';
import Paralizados from '../components/application/paralizados';
import Facturas from '../components/application/facturas';
import Notifications from '../components/application/notifications';
import CrossSelling from '../components/application/crossselling';
import PedidosCarrier from '../components/application/pedidos-carrier';
import SettingsPage from '../components/application/settings/settings.page';

export const routes = [
	{
		path: '/',
		Component: Dashboard,
	},
	{
		path: '/completos',
		Component: Completos,
	},
	{
		path: '/inglaterra',
		Component: Inglaterra,
	},
	{
		path: '/estadosunidos',
		Component: EstadosUnidos,
	},
	{
		path: '/estadosunidosbloqueados',
		Component: EstadosUnidosBloqueados,
	},
	{
		path: '/guanxe',
		Component: Guanxe,
	},
	{
		path: '/amazon',
		Component: Amazon,
	},
	{
		path: '/aliexpress',
		Component: AliExpress,
	},
	{
		path: '/miravia',
		Component: Miravia,
	},
	{
		path: '/incompletos',
		Component: Incompletos,
	},
	{
		path: '/paralizados',
		Component: Paralizados,
	},
	{
		path: '/facturas',
		Component: Facturas,
	},
	{
		path: '/notifications',
		Component: Notifications,
	},
	{
		path: '/cross-selling',
		Component: CrossSelling,
	},
	{
		path: '/orders/:carrierName',
		Component: PedidosCarrier,
	},
	{
		path: '/settings',
		Component: SettingsPage,
	},
];
