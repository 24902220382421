import React from 'react';
import DialogoConfirmacion from './DialogoConfirmacion';
import PanToolIcon from '@material-ui/icons/PanTool';
import { esPedidoCompleto, esPedidoEnPreparacion } from '../utils';
import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';
import { fetchBloquearPedidoApi } from '../api/common.services';
import { BLOQUEAR_PEDIDO } from '../redux/actionTypes';
import { useDispatch } from 'react-redux';

export default function BotonBloquear({ pedido }) {
	const { ID } = pedido;
	const { NOMBRECOMERCIAL } = pedido.SHIPMENT;

	const dispatch = useDispatch();

	const bloquearPedido = async () => {
		const { success, data } = await fetchBloquearPedidoApi({ ID });
		if (success) {
			dispatch({
				type: BLOQUEAR_PEDIDO,
				payload: {
					ID,
					LOCK: data,
				},
			});
		}
	};

	return (
		<DialogoConfirmacion
			title='Bloquear pedido'
			buttonProps={{
				disabled: esPedidoEnPreparacion(pedido),
				variant: 'contained',
				endIcon: <PanToolIcon />,
			}}
			textButtonOpen='Bloquear'
			textButtonConfirmar='Bloquear'
			handleConfirmar={bloquearPedido}>
			<div style={{ width: 550 }}>
				{esPedidoCompleto(pedido) ? (
					<Typography style={{ color: green[700] }}>
						LISTO PARA PREPARAR
					</Typography>
				) : (
					<Typography style={{ color: red[700] }}>INCOMPLETO</Typography>
				)}
				<strong>ID Pedido:</strong> {ID}
				<br />
				<strong>Cliente:</strong> {NOMBRECOMERCIAL}
				<br />
				<Alert
					severity='info'
					style={{
						marginBottom: '1rem',
						marginTop: '1rem',
					}}>
					Bloquear un pedido impide su preparacion y lo excluye del reparto de
					stock, liberando los productos que podran ser usados por otros
					pedidos.
					<br />
					<strong>
						Todos los pedidos a tienda activos para este pedido serán
						cancelados.
					</strong>
				</Alert>
				<Typography
					style={{
						color: red[700],
						textAlign: 'right',
					}}>
					¿Seguro que desea bloquear el pedido?
				</Typography>
			</div>
		</DialogoConfirmacion>
	);
}
