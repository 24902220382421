import { call, put, takeLatest } from 'redux-saga/effects';
import {
	fetchPedidosApi,
	fetchPreparacionesApi,
} from '../../api/common.services';
import { WATCH_PEDIDOS } from '../actionTypes';
import { updatePedidos } from './action';
import moment from 'moment';
import { getPedidosPreparar } from '../../utils';

function* fetchPedidosAsyn() {
	yield put(
		updatePedidos({
			fetching: true,
		})
	);
	const { success, data } = yield call(fetchPedidosApi);
	const pedidos = success ? data : [];

	const pedidosPreparar = getPedidosPreparar(pedidos);
	const { data: preparaciones = [] } = yield call(() =>
		fetchPreparacionesApi({ orderIds: pedidosPreparar.map((o) => o.id) })
	);
	pedidosPreparar.forEach((o) => {
		const preparacion = preparaciones.find((p) => p.SUPEDIDO === o.id);
		if (preparacion) {
			o.PICKING = true;
		}
	});

	yield put(
		updatePedidos({
			fetching: false,
			ultimaActualizacion: moment(),
			pedidos,
		})
	);
}

export function* watcherPedidos() {
	yield takeLatest(WATCH_PEDIDOS, fetchPedidosAsyn);
}
