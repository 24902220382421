import React from 'react';
import FastForwardIcon from '@material-ui/icons/FastForward';
import { blueGrey } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import { getPedidosATiendaFuturos, getTraspasosFuturos } from '../../utils';
import { tiendasData } from '../../data/tiendas';
import { Badge } from '@material-ui/core';

export default function IconFutureRequests({
	order,
	resumeType = 'total',
	productId = null,
}) {
	const [open, setOpen] = React.useState(false);

	let pedidosATiendaFuturos = getPedidosATiendaFuturos(order);
	if (productId) {
		pedidosATiendaFuturos = pedidosATiendaFuturos.filter(
			(r) => r.PRODUCT_ID === productId
		);
	}

	let traspasosFuturos = getTraspasosFuturos(order);
	if (productId) {
		traspasosFuturos = traspasosFuturos.filter(
			(r) => r.PRODUCT_ID === productId
		);
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const totalArticulosPedidos = pedidosATiendaFuturos.reduce(
		(total, r) => total + r.AMOUNT,
		0
	);
	const totalTraspasosFuturos = traspasosFuturos.reduce(
		(total, r) => total + r.AMOUNT,
		0
	);

	let totalResume = totalArticulosPedidos + totalTraspasosFuturos;

	if (resumeType === 'pedidosTienda') {
		totalResume = totalArticulosPedidos;
	}

	if (resumeType === 'traspasos') {
		totalResume = totalTraspasosFuturos;
	}

	return (
		<>
			<Badge
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				overlap={'rectangular'}
				badgeContent={totalResume}>
				<FastForwardIcon
					onClick={handleClickOpen}
					style={{ color: blueGrey[200] }}
				/>
			</Badge>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll='paper'
				aria-labelledby='scroll-dialog-title'
				aria-describedby='scroll-dialog-description'>
				<DialogTitle>Próximas solicitudes</DialogTitle>
				<DialogContent dividers>
					<Alert severity={'info'} style={{ marginBottom: '1rem' }}>
						El calculo del reparto de stock indica que este pedido solicitará
						articulos próximamente
					</Alert>

					<DialogContentText component='div'>
						{totalArticulosPedidos > 0 && (
							<>
								<Typography variant='body1' style={{ color: '#333' }}>
									Este calculo incluye próximos <b>pedidos a tienda.</b>
								</Typography>
								<Typography
									variant='body1'
									component='div'
									style={{ marginBottom: '0.5rem' }}>
									{pedidosATiendaFuturos.map((r, i) => {
										const articulo = order.DETAILS.find(
											(d) => d.PRODUCT_ID === r.PRODUCT_ID
										);
										const descripcion = articulo
											? articulo.DESCRIPTION
											: r.PRODUCT_ID;
										const almacen = tiendasData[r.STORE_TO_ID]
											? tiendasData[r.STORE_TO_ID].nombre
											: r.STORE_TO_ID;
										return (
											<div key={i}>
												{r.AMOUNT} unidades de {descripcion} a {almacen}
											</div>
										);
									})}
								</Typography>
							</>
						)}

						{totalTraspasosFuturos > 0 && (
							<>
								<Typography variant='body1' style={{ color: '#333' }}>
									Este calculo incluye próximas solicitudes de <b>traspasos.</b>
								</Typography>
								<Typography
									variant='body1'
									component='div'
									style={{ marginTop: '0.5rem' }}>
									{traspasosFuturos.map((r, i) => {
										const articulo = order.DETAILS.find(
											(d) => d.PRODUCT_ID === r.PRODUCT_ID
										);
										const descripcion = articulo
											? articulo.DESCRIPTION
											: r.PRODUCT_ID;
										const almacen = tiendasData[r.STORE_TO_ID]
											? tiendasData[r.STORE_TO_ID].nombre
											: r.STORE_TO_ID;
										return (
											<div key={i}>
												{r.AMOUNT} unidades de {descripcion} a {almacen}
											</div>
										);
									})}
								</Typography>
							</>
						)}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
}
