import { FETCH_USER, SET_USER_ONLINE } from '../actionTypes';

export const fetchUser = (payload) => ({
	type: FETCH_USER,
	payload,
});

export const setUserOnline = (online) => ({
	type: SET_USER_ONLINE,
	payload: { online },
});
