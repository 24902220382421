import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function ErrorCodeDialog({
	open = false,
	handleClose = () => null,
	type = 'wrongCode',
}) {
	return (
		<Dialog fullWidth open={open}>
			<DialogContent dividers>
				<Alert variant='filled' severity='error'>
					El código introducido NO ha sido añadido al pedido.
				</Alert>

				{type === 'wrongCode' && (
					<Alert severity='error' style={{ marginBottom: '1rem' }}>
						<AlertTitle>Código introducido erroneo</AlertTitle>
						El código introducido no pertenece a ningun producto del pedido.
					</Alert>
				)}

				{type === 'wrongAmount' && (
					<Alert severity='error' style={{ marginBottom: '1rem' }}>
						<AlertTitle>Limite MAXIMO de unidades</AlertTitle>
						El código introducido pertenece a un producto que ya está completo.
					</Alert>
				)}

				<Typography gutterBottom style={{ padding: '1rem' }}>
					Envia solo las unidades necesarias de los articulos indicados para
					completar el pedido.
					<br />
					El pedido ha sido optimizado para obtener la mejor distribución de los
					productos.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					autoFocus
					onClick={handleClose}
					color={'primary'}
					variant={'contained'}>
					Continuar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
