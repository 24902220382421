import { SET_STATUS } from '../actionTypes';

const initialState = {
	dbSabinaStatus: true,
};

const StatusReducer = (state = initialState, { type, payload }) => {
	const { dbSabinaStatus } = { ...state, ...payload };

	switch (type) {
		case SET_STATUS:
			return {
				dbSabinaStatus,
			};

		default:
			return {
				...state,
			};
	}
};

export default StatusReducer;
