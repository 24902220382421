import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TablaPedidosTienda from '../tablas/TablaPedidosTienda';
import { Badge } from '@material-ui/core';
import { blue, green, grey, orange, red } from '@material-ui/core/colors';
import {
	esPedidoATiendaExtraviado,
	esPedidoATiendaRetrasado,
	esPedidoCompleto,
	getPedidosATiendaActivos,
} from '../../utils';

function rand() {
	return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
	const top = 50 + rand();
	const left = 50 + rand();

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 800,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

export default function IconoPedidosTienda({ pedido }) {
	const { ID } = pedido;
	const { NOMBRECOMERCIAL } = pedido.SHIPMENT;

	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const body = (
		<div style={modalStyle} className={classes.paper}>
			<div
				style={{
					display: 'flex',
					alignItems: 'flex-start',
				}}>
				<LocalShippingIcon
					style={{
						fontSize: 40,
						marginRight: '0.5rem',
					}}
				/>
				<h2
					id='simple-modal-title'
					style={{
						marginTop: 7,
						marginBottom: 0,
					}}>
					{' '}
					Pedidos a tienda
				</h2>
			</div>

			<div>
				<strong>ID Pedido:</strong> {ID}
				<br />
				<strong>Cliente:</strong> {NOMBRECOMERCIAL}
				<br />
				<br />
				<div style={{ height: 300 }}>
					<TablaPedidosTienda pedido={pedido} />
				</div>
			</div>
		</div>
	);

	// ---------------------------------------
	// Con pedidos a tienda activos en tiempo
	let colorIcono = blue[700];

	// ---------------------------------------
	// Sin pedidos a tienda activos
	if (getPedidosATiendaActivos(pedido).length === 0) {
		colorIcono = grey[400];
	}

	// ---------------------------------------
	// Pedido a tienda retrasado
	if (pedido.REQUESTS.some((pT) => esPedidoATiendaRetrasado(pT))) {
		colorIcono = orange[500];
	}

	// ---------------------------------------
	// Pedido a tienda posiblemente extraviado
	//
	if (pedido.REQUESTS.some((pT) => esPedidoATiendaExtraviado(pT, pedido))) {
		colorIcono = red[500];
	}

	// ---------------------------------------
	// Pedido completo
	if (esPedidoCompleto(pedido)) {
		colorIcono = green[700];
	}

	// ---------------------------------------
	// Sin stock
	// Icono diferente sin stock
	//
	// if(true){
	//     colorIcono = red[500]
	// }

	let totalArticulosPedidos = 0;
	getPedidosATiendaActivos(pedido).map((p) => {
		totalArticulosPedidos += p.AMOUNT;
		return p;
	});

	return (
		<>
			<Badge
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				badgeContent={totalArticulosPedidos}
				overlap={'rectangular'}>
				<LocalShippingIcon onClick={handleOpen} style={{ color: colorIcono }} />
			</Badge>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'>
				{body}
			</Modal>
		</>
	);
}
