import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import LoopIcon from '@material-ui/icons/Loop';
import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	listItemBadge: {
		backgroundColor: 'red',
		width: '18px',
		height: '18px',
		borderRadius: '50%',
		textAlign: 'center',
		color: 'white',
		fontSize: '11px',
		paddingTop: '2px',
		position: 'relative',
		left: '-11px',
		top: '-4px',
	},
}));

function ListItemLink(props) {
	const { icon, primary, secondary = '', to, active, badge, updating } = props;

	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				<Link to={to} ref={ref} {...itemProps} />
			)),
		[to]
	);
	const className = active ? 'liListItemLinkActive' : 'liListItemLinkInactive';

	const classes = useStyles();

	return (
		<li className={className}>
			<ListItem button component={renderLink}>
				{icon ? (
					<ListItemIcon>
						{icon}
						{badge && !updating ? (
							<span className={classes.listItemBadge}>{badge}</span>
						) : null}
					</ListItemIcon>
				) : null}
				<ListItemText primary={primary} secondary={secondary} />
			</ListItem>
		</li>
	);
}

ListItemLink.propTypes = {
	icon: PropTypes.element,
	// primary: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};

const ListItemSeccion = ({
	loading = false,
	updating = false,
	numPedidos = null,
	numProductos = null,
	...props
}) => {
	const secondaryText = (
		<span
			style={{
				display: 'flex',
				alignItems: 'center',
			}}>
			{!loading && (numPedidos !== null || numProductos !== null) && (
				<>
					{numPedidos === 0 && numPedidos + ' pedidos'}
					{numPedidos > 0 && (
						<span style={{ color: blue[700] }}>{numPedidos} pedidos</span>
					)}
					{numProductos === 0 && numProductos + ' productos'}
					{numProductos > 0 && (
						<span style={{ color: blue[700] }}>{numProductos} productos</span>
					)}
				</>
			)}
			{loading && <span style={{ color: blue[400] }}>Cargando...</span>}
			{updating && (
				<LoopIcon
					style={{
						color: blue[400],
						marginLeft: '0.3rem',
						fontSize: '12px',
					}}
				/>
			)}
		</span>
	);

	return (
		<ListItemLink
			active={
				numPedidos == null ||
				numPedidos > 0 ||
				numProductos == null ||
				numProductos > 0
			}
			{...props}
			secondary={secondaryText}
		/>
	);
};

export default ListItemSeccion;
