import React, { useCallback, useEffect } from 'react';
import Contenido from '../../../layout/Contenido';
import TablaFacturas from '../../tablas/TablaFacturas';
import { useDispatch, useSelector } from 'react-redux';
import { WATCH_FACTURAS } from '../../../redux/actionTypes';
import DialogoCrearFactura from '../../DialogoCrearFactura';

export default function Facturas() {
	const dispatch = useDispatch();

	const { fetching: fetchingUser, user } = useSelector(
		(content) => content.User
	);
	const { fetching: fetchingFacturas, facturas } = useSelector(
		(content) => content.Facturas
	);

	const cargarFacturas = useCallback(() => {
		if (user) {
			dispatch({ type: WATCH_FACTURAS });
		}
	}, [dispatch, user]);

	useEffect(cargarFacturas, [cargarFacturas]);

	return (
		<Contenido
			title='Facturas'
			acciones={
				<>
					<DialogoCrearFactura handleConfirmar={cargarFacturas} />
				</>
			}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				<TablaFacturas
					loading={fetchingUser || fetchingFacturas}
					facturas={facturas}
				/>
			</div>
		</Contenido>
	);
}
