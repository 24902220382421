import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchParalizadosApi } from '../../api/common.services';
import { WATCH_PARALIZADOS } from '../actionTypes';
import { updateParalizados } from './action';
import moment from 'moment';

function* fetchParalizadosAsyn() {
	yield put(
		updateParalizados({
			fetching: true,
		})
	);
	let { success, data } = yield call(fetchParalizadosApi);
	yield put(
		updateParalizados({
			fetching: false,
			ultimaActualizacion: moment(),
			paralizados: success ? data : [],
		})
	);
}

export function* watcherParalizados() {
	yield takeLatest(WATCH_PARALIZADOS, fetchParalizadosAsyn);
}
