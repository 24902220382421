import React, { useEffect, useState } from 'react';

import { DataGrid, useGridSlotComponentProps } from '@material-ui/data-grid';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

import Button from '@material-ui/core/Button';
import {
	agruparPedidos,
	esPedidoBloqueado,
	getPedidosATiendaActivos,
	getTraspasosActivos,
	tieneProductoCrossSelling,
	tieneProductoSinStock,
} from '../../utils';
import { useSelector } from 'react-redux';

import Pagination from '@material-ui/lab/Pagination';
import { blue } from '@material-ui/core/colors';
import { Badge } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import LinkPrepararPedidosAgrupados from '../LinkPrepararPedidosAgrupados';

function CustomPagination() {
	const { state, apiRef } = useGridSlotComponentProps();

	return (
		<Pagination
			style={{ display: 'flex' }}
			color='primary'
			count={state.pagination.pageCount}
			page={state.pagination.page + 1}
			onChange={(event, value) => apiRef.current.setPage(value - 1)}
		/>
	);
}

export default function TablaPedidosAgrupados({
	loading = false,
	pedidos = [],
	showTraspasos = false,
	showPedidosATienda = false,
}) {
	const { showTraspasos: showTraspasosEquipo } = useSelector(
		(content) => content.User
	);
	const [pedidosOrdenados, setPedidosOrdenados] = useState([]);

	useEffect(() => {
		setPedidosOrdenados(
			agruparPedidos(pedidos)
				// .filter((position) => position.orders.length > 1)
				.sort((a, b) => {
					if (b.orders.length === 1 && a.orders.length === 1) {
						return a.orders[0].UNIXDATE - b.orders[0].UNIXDATE;
					}
					return b.orders.length - a.orders.length;
				})
		);
	}, [pedidos]);

	const columns = [
		{
			sortable: false,
			field: 'DETALLES',
			headerName: 'Detalles',
			width: 120,
			renderCell: ({ row: { orders } }) => {
				if (orders.length > 1) {
					return <Button color={'primary'}>{orders.length} PEDIDOS</Button>;
				} else {
					const [order] = orders;
					return (
						<Button color={'primary'} disabled>
							{order.ID}
						</Button>
					);
				}
			},
		},
		{
			sortable: false,
			field: 'pedidosATienda',
			width: 50,
			hide: !showPedidosATienda,
			renderHeader: () => <LocalShippingIcon />,
			renderCell: ({ row: position }) => {
				const posibleOrders = position.orders.filter(
					(order) =>
						!esPedidoBloqueado(order) &&
						!tieneProductoCrossSelling(order) &&
						!tieneProductoSinStock(order)
				);
				let pedidosATiendaTotal = 0;
				posibleOrders.forEach((order) => {
					pedidosATiendaTotal += getPedidosATiendaActivos(order).length;
				});

				if (pedidosATiendaTotal > 0) {
					return (
						<Badge
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							badgeContent={pedidosATiendaTotal}
							overlap={'rectangular'}>
							<LocalShippingIcon style={{ color: blue[700] }} />
						</Badge>
					);
				}
				return '';
			},
		},
		{
			sortable: false,
			field: 'traspasos',
			width: 50,
			hide: !showTraspasos || !showTraspasosEquipo,
			renderHeader: () => <SyncAltIcon />,
			renderCell: ({ row: position }) => {
				if (
					position.orders.some((order) => getTraspasosActivos(order).length > 0)
				) {
					return <SyncAltIcon />;
				}

				return '';
			},
		},
		{
			sortable: false,
			field: 'HAS_GIFT_WRAP',
			width: 50,
			renderHeader: () => <CardGiftcardIcon />,
			renderCell: ({ row: position }) => {
				const total = position.orders.filter(
					(order) => order.HAS_GIFT_WRAP
				).length;
				if (total) {
					return (
						<Badge
							color='secondary'
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							badgeContent={total}>
							<CardGiftcardIcon style={{ color: blue[700] }} />
						</Badge>
					);
				}
				return '';
			},
		},
		{
			sortable: false,
			field: 'HAS_LASER_ENGRAVINGS',
			headerName: 'Laser',
			width: 50,
			renderHeader: () => <FlashOnIcon />,
			renderCell: ({ row: position }) => {
				const total = position.orders.filter(
					(order) => order.HAS_LASER_ENGRAVINGS
				).length;
				if (total) {
					return (
						<Badge
							color='secondary'
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							badgeContent={total}
							overlap={'rectangular'}>
							<FlashOnIcon style={{ color: blue[700] }} />
						</Badge>
					);
				}
				return '';
			},
		},

		{
			sortable: false,
			field: 'DETAILS_RESUME',
			width: 60,
			renderHeader: () => <WidgetsOutlinedIcon />,
			renderCell: ({ row: position }) => {
				const ARTICLES = Object.keys(position.products).length;
				let AMOUNT = 0;
				position.orders.forEach((order) => {
					AMOUNT += order.DETAILS_RESUME.AMOUNT;
				});
				return (
					<span>
						{ARTICLES}/{AMOUNT}
					</span>
				);
			},
		},
		{
			sortable: false,
			field: 'Productos',
			headerName: 'Productos',
			flex: 1,
			renderCell: ({ row: { orders, products } }) => {
				const productNames = Object.values(products).map((productName, pos) => (
					<Chip
						key={`chip_${productName}_${pos}`}
						label={productName}
						variant={'outlined'}
						style={{ marginLeft: '0.2rem' }}
						color={orders.length > 1 ? 'primary' : 'default'}
					/>
				));

				// const [firstOrder] = orders;
				// const productNames = firstOrder.DETAILS.map((detail) => (
				// 	<Chip
				// 		label={detail.DESCRIPTION}
				// 		variant={'outlined'}
				// 		style={{ marginLeft: '0.2rem' }}
				// 		color={orders.length > 1 ? 'primary' : 'info'}
				// 	/>
				// ));

				return productNames;
			},
		},
		{
			sortable: false,
			field: 'accion-preparar',
			headerName: 'Preparar',
			width: 150,
			// hide: !showPreparar,
			renderCell: ({ row: { orders, products } }) => {
				if (orders.length) {
					return (
						<LinkPrepararPedidosAgrupados orders={orders} products={products} />
					);
				}
				return (
					<Button variant='contained' color={'primary'} disabled>
						PREPARAR
					</Button>
				);
			},
		},
	];

	return (
		<>
			<DataGrid
				rows={pedidosOrdenados}
				columns={columns}
				pageSize={100}
				loading={loading}
				disableColumnMenu
				disableSelectionOnClick
				components={{
					Pagination: CustomPagination,
				}}
			/>
		</>
	);
}
