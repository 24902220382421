import React from 'react';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { Alert, AlertTitle } from '@material-ui/lab';
import { blue, red } from '@material-ui/core/colors';
import TablaGrabadosLaser from '../tablas/TablaGrabadosLaser';
import { Badge, Box } from '@material-ui/core';
import { getPdfLaserUrl } from '../../utils';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PrintButton from '../botones/PrintButton';

function Dialogo({ title, open, onClose, children, actions }) {
	return (
		<Dialog fullWidth scroll='paper' open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers style={{ minHeight: '8rem' }}>
				{children}
			</DialogContent>
			{actions && <DialogActions>{actions}</DialogActions>}
		</Dialog>
	);
}

export default function IconoLaser({ pedido }) {
	const {
		ID,
		HAS_LASER_ENGRAVINGS,
		SHIPMENT: { NOMBRECOMERCIAL },
		AMOUNT_LASER_ENGRAVINGS,
	} = pedido;
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const body = (
		<Box>
			<Box>
				<strong>ID Pedido:</strong> {ID}
				<br />
				<strong>Cliente:</strong> {NOMBRECOMERCIAL}
				<br />
				<br />
				{HAS_LASER_ENGRAVINGS ? (
					<Box
						style={{
							height: '200px',
							width: '100%',
						}}>
						<TablaGrabadosLaser pedido={pedido} />
					</Box>
				) : (
					<Alert severity='error' style={{ marginTop: '1rem' }}>
						<AlertTitle>Error obteniendo los datos</AlertTitle>
						Contacte con el administrador.
					</Alert>
				)}
			</Box>
		</Box>
	);

	return (
		<>
			{HAS_LASER_ENGRAVINGS ? (
				<Badge
					color='secondary'
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					badgeContent={AMOUNT_LASER_ENGRAVINGS}
					overlap={'rectangular'}>
					<FlashOnIcon onClick={handleOpen} style={{ color: blue[700] }} />
				</Badge>
			) : (
				<ErrorOutlineOutlinedIcon
					style={{
						color: red[500],
						marginTop: '18px',
					}}
					onClick={handleOpen}
				/>
			)}

			<Dialogo
				title={'Grabado Láser'}
				open={open}
				onClose={handleClose}
				actions={
					<PrintButton
						url={getPdfLaserUrl(ID)}
						disabled={!HAS_LASER_ENGRAVINGS}
					/>
				}>
				{body}
			</Dialogo>
		</>
	);
}
