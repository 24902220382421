import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { tiendasData } from '../../data/tiendas';
import moment from 'moment';
import DialogoConfirmacion from '../DialogoConfirmacion';
import { fetchCancelarPedidoTiendaApi } from '../../api/common.services';
import { blue, green, orange, red } from '@material-ui/core/colors';
import {
	esPedidoATiendaCancelado,
	esPedidoATiendaEnviado,
	esPedidoATiendaExtraviado,
	esPedidoATiendaRecibido,
	esPedidoATiendaRetrasado,
	getHorasDesde,
	getPedidosATiendaActivos,
	getPedidosATiendaFuturos,
	getTraspasosFuturos,
	getUnidadesPendientes,
} from '../../utils';
import IconoAyudaExtraviado from '../iconos/IconoAyudaExtraviado';
import { useDispatch, useSelector } from 'react-redux';
import { CANCELAR_PEDIDO_TIENDA } from '../../redux/actionTypes';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

export default function TablaPedidosTienda({ pedido, PRODUCT_ID = null }) {
	const dispatch = useDispatch();

	const { esAdmin } = useSelector((content) => content.User);

	const cancelarPedidoTienda = async ({ ID }) => {
		const { success, data: CANCELED_UNIXDATE } =
			await fetchCancelarPedidoTiendaApi({ ID });
		if (success) {
			dispatch({
				type: CANCELAR_PEDIDO_TIENDA,
				payload: {
					ORDER_ID: pedido.ID,
					ID,
					CANCELED_UNIXDATE,
				},
			});
		}
	};

	const columns = [
		{
			sortable: false,
			field: 'STORE_TO_ID',
			headerName: 'Almacen',
			hide: !esAdmin,
			renderCell: ({ value: STORE_ID, row: producto }) => {
				return tiendasData[STORE_ID] ? tiendasData[STORE_ID].nombre : STORE_ID;
			},
			width: 110,
		},
		{
			sortable: false,
			field: 'PRODUCT_ID',
			headerName: 'Producto',
			renderCell: ({ value: PRODUCT_ID }) => {
				const articulo = pedido.DETAILS.find(
					(d) => d.PRODUCT_ID === PRODUCT_ID
				);
				if (articulo) {
					return PRODUCT_ID + ' - ' + articulo.DESCRIPTION;
				}
				return PRODUCT_ID;
			},
			flex: 1,
		},
		{
			sortable: false,
			field: 'AMOUNT',
			headerName: <LocalShippingIcon style={{ marginTop: 20 }} />,
			renderCell: ({ value }) => {
				return (
					<div
						style={{
							textAlign: 'center',
							width: '100%',
						}}>
						{value}
					</div>
				);
			},
			width: 60,
		},
		{
			sortable: false,
			field: 'UNIXDATE',
			headerName: 'Fecha',
			renderCell: ({ value: unixdate, row: pedidoATienda }) => {
				// Pedido cancelado
				if (esPedidoATiendaCancelado(pedidoATienda)) {
					return (
						<div style={{ lineHeight: '20px' }}>
							<span style={{ color: red[500] }}>Cancelado</span>
						</div>
					);
				}

				const articulo = pedido.DETAILS.find(
					(d) => d.PRODUCT_ID === pedidoATienda.PRODUCT_ID
				);
				const traspasosFuturos = getTraspasosFuturos(pedido);

				// Pedido recibido en almacen de traspasos
				if (
					traspasosFuturos.some(
						(r) => r.PRODUCT_ID.toString() === articulo.PRODUCT_ID.toString()
					)
				) {
					return (
						<div style={{ lineHeight: '20px' }}>
							<span
								style={{
									color: green[500],
									display: 'flex',
								}}>
								<LocalShippingIcon style={{ marginRight: '0.5rem' }} />
								Recibido
								<br />
								Esperando traspaso
							</span>
						</div>
					);
				}

				// Pedido recibido
				if (getUnidadesPendientes(articulo) === 0) {
					return (
						<div style={{ lineHeight: '20px' }}>
							<span
								style={{
									color: green[500],
									display: 'flex',
								}}>
								<LocalShippingIcon style={{ marginRight: '0.5rem' }} />
								Recibido
							</span>
						</div>
					);
				}

				if (!unixdate) {
					return 'Próximamente...';
				}

				const horasPasadas = getHorasDesde(unixdate);
				const horasPasadasEnvio = pedidoATienda.SENDED_UNIXDATE
					? getHorasDesde(pedidoATienda.SENDED_UNIXDATE)
					: 0;

				return (
					<div style={{ lineHeight: '20px' }}>
						{esPedidoATiendaExtraviado(pedidoATienda) && (
							<>
								<span style={{ color: red[500] }}>
									Extraviado <IconoAyudaExtraviado />
								</span>
							</>
						)}

						{!esPedidoATiendaRetrasado(pedidoATienda) &&
							esPedidoATiendaEnviado(pedidoATienda) && (
								<span
									style={{
										color: blue[500],
										display: 'flex',
									}}>
									<LocalShippingIcon style={{ marginRight: '0.5rem' }} />
									En transito hace{' '}
									{horasPasadasEnvio > 1
										? horasPasadasEnvio + ' horas'
										: 'poco'}
								</span>
							)}

						{esPedidoATiendaRetrasado(pedidoATienda) &&
							esPedidoATiendaEnviado(pedidoATienda) && (
								<span
									style={{
										color: orange[500],
										display: 'flex',
									}}>
									<LocalShippingIcon style={{ marginRight: '0.5rem' }} />
									En transito hace {parseInt(horasPasadasEnvio / 24)} días
								</span>
							)}

						{esPedidoATiendaRetrasado(pedidoATienda) &&
							!esPedidoATiendaEnviado(pedidoATienda) && (
								<span style={{ color: orange[500] }}>
									Hace {parseInt(horasPasadas / 24)} días
								</span>
							)}

						{!esPedidoATiendaCancelado(pedidoATienda) &&
							!esPedidoATiendaRecibido(pedidoATienda) &&
							!esPedidoATiendaExtraviado(pedidoATienda) &&
							!esPedidoATiendaRetrasado(pedidoATienda) &&
							!esPedidoATiendaEnviado(pedidoATienda) &&
							horasPasadas > 0 && <span>Hace {horasPasadas} horas</span>}
						{!esPedidoATiendaCancelado(pedidoATienda) &&
							!esPedidoATiendaRecibido(pedidoATienda) &&
							!esPedidoATiendaExtraviado(pedidoATienda) &&
							!esPedidoATiendaRetrasado(pedidoATienda) &&
							!esPedidoATiendaEnviado(pedidoATienda) &&
							horasPasadas === 0 && <span>Hace unos minutos</span>}
						<div>{moment.unix(unixdate).format('DD/MM/YYYY HH:mm')}</div>
					</div>
				);
			},
			width: 200,
		},
		{
			sortable: false,
			field: 'accion-cancelar',
			headerName: 'Acciones',
			hide: !esAdmin,
			renderCell: ({ row: pedidoATienda }) => {
				const { ID, UNIXDATE, PRODUCT_ID } = pedidoATienda;
				const articulo = pedido.DETAILS.find(
					(d) => d.PRODUCT_ID === pedidoATienda.PRODUCT_ID
				);

				const traspasosFuturos = getTraspasosFuturos(pedido);
				if (
					getUnidadesPendientes(articulo) === 0 ||
					traspasosFuturos.some(
						(r) => r.PRODUCT_ID.toString() === PRODUCT_ID.toString()
					) ||
					!UNIXDATE ||
					esPedidoATiendaCancelado(pedidoATienda) ||
					esPedidoATiendaRecibido(pedidoATienda)
				) {
					return ' ';
				}

				return (
					<DialogoConfirmacion
						title='Cancelar pedido a tienda'
						text='¿Estas seguro de cancelar este pedido a tienda?'
						textButtonOpen='Cancelar'
						buttonProps={{
							variant: 'contained',
							color:
								esPedidoATiendaExtraviado(pedidoATienda) ||
								esPedidoATiendaRetrasado(pedidoATienda)
									? 'secondary'
									: 'default',
						}}
						textButtonConfirmar='Cancelar pedido'
						textButtonCancelar='Volver'
						handleConfirmar={() => cancelarPedidoTienda({ ID })}>
						<div
							style={{
								height: 130,
								width: 450,
							}}>
							<DataGrid
								rows={[pedidosATienda.find((p) => p.ID === ID)]}
								columns={columns.filter(
									(c) => c.field === 'PRODUCT_ID' || c.field === 'CANTIDAD'
								)}
								pageSize={100}
								hideFooter
								hideFooterPagination
								hideFooterRowCount
								disableColumnMenu
								disableSelectionOnClick
							/>
						</div>
					</DialogoConfirmacion>
				);
			},
			width: 150,
		},
	];

	let pedidosATienda = [
		...getPedidosATiendaActivos(pedido),
		...getPedidosATiendaFuturos(pedido),
	];

	pedidosATienda = pedidosATienda
		.map((p, i) => ({ id: i, ...p }))
		.sort((a, b) => {
			const aUnixdate = a.UNIXDATE || moment().unix();
			const bUnixdate = b.UNIXDATE || moment().unix();
			return aUnixdate - bUnixdate;
		});

	if (PRODUCT_ID) {
		pedidosATienda = pedidosATienda.filter(
			(p) => p.PRODUCT_ID.toString() === PRODUCT_ID.toString()
		);
	}

	return (
		<DataGrid
			rows={pedidosATienda}
			columns={columns}
			pageSize={100}
			hideFooter
			hideFooterPagination
			hideFooterRowCount
			disableColumnMenu
			disableSelectionOnClick
		/>
	);
}
