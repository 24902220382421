import { getCarrier } from '../../utils';

export default function CarrierListImage({ order }) {
	let src = null;
	switch (getCarrier(order)) {
		case 'seur':
			src = '/carrier/logoSeur.png';
			break;
		case 'seur-europa':
			src = '/carrier/logoSeurEuropa.png';
			break;
		case 'seur-canarias':
			src = '/carrier/logoSeurCanarias.png';
			break;
		case 'tipsa':
			src = '/carrier/logoTipsa.png';
			break;
		case 'gls':
			src = '/carrier/logoGLS.png';
			break;
		case 'dhl':
			src = '/carrier/logoDHL.png';
			break;
		case 'dhl-europa':
			src = '/carrier/logoDHLEuropa.png';
			break;
		case 'pickup-point':
			src = '/carrier/logoPickupPoint.png';
			break;
		default:
			src = null;
			break;
	}

	if (!src) {
		return order.TRANSPORTE;
	}
	return <img alt={order.TRANSPORTE} src={src} style={{ height: 25 }} />;
}
