import React from 'react';
import DialogoConfirmacion from './DialogoConfirmacion';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Avatar, ListItemAvatar } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { prepararPedidoGrupal } from '../api/common.services';

const useStyles = makeStyles((theme) => ({
	primary: {
		color: theme.palette.getContrastText(blue[500]),
		backgroundColor: blue[500],
	},
}));

export default function LinkPrepararPedidosAgrupados({ orders, products }) {
	const classes = useStyles();

	const productsResume = {};
	orders.forEach((order) => {
		order.DETAILS.forEach((product) => {
			const { PRODUCT_ID, DESCRIPTION, AMOUNT } = product;
			productsResume[PRODUCT_ID] = productsResume[PRODUCT_ID] || {
				PRODUCT_ID,
				NAME: DESCRIPTION,
				AMOUNT: 0,
			};
			productsResume[PRODUCT_ID].AMOUNT += AMOUNT;
		});
	});
	const productsValuesResume = Object.values(productsResume);

	const handleClickPrepare = () =>
		prepararPedidoGrupal({
			orders,
			products: productsValuesResume,
		});

	return (
		<DialogoConfirmacion
			title={'Pedidos agrupados'}
			textButtonOpen={'PREPARAR'}
			buttonProps={{
				variant: 'contained',
				color: 'primary',
				disabled: orders.length === 1,
			}}
			handleConfirmar={handleClickPrepare}
			textButtonConfirmar={'PREPARAR (Descargar PDF)'}
			buttonConfirmarProps={{
				variant: 'contained',
				color: 'primary',
			}}>
			<Typography variant={'h6'} gutterBottom>
				Resumen:
			</Typography>
			<List component='nav'>
				{productsValuesResume.map((productResume, pos) => (
					<ListItem key={`productResume_${pos}`}>
						<ListItemAvatar>
							<Avatar className={classes.primary} variant={'rounded'}>
								{productResume.AMOUNT}
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={productResume.NAME} />
						{productsValuesResume.length !== pos && <Divider />}
					</ListItem>
				))}
			</List>
		</DialogoConfirmacion>
	);
}
