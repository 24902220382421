export const tiendasData = {
	1: {
		nombre: 'Servicio',
		isla: 'Gran Canaria',
	},
	6: {
		nombre: 'Alegranza',
		isla: 'Gran Canaria',
	},
	61: {
		nombre: 'Rubiesas',
		isla: 'Gran Canaria',
	},
	12: {
		nombre: 'Complementos',
		isla: 'Gran Canaria',
	},
	14: {
		nombre: 'Sport Central',
		isla: 'Gran Canaria',
	},
	4: {
		nombre: 'Cita',
		isla: 'Gran Canaria',
	},
	10: {
		nombre: 'Corona Roja',
		isla: 'Gran Canaria',
	},
	7: {
		nombre: 'Escorial',
		isla: 'Gran Canaria',
	},
	170: {
		nombre: 'Boulevard Meloneras',
		isla: 'Gran Canaria',
	},
	175: {
		nombre: 'Las Arenas',
		isla: 'Gran Canaria',
	},
	54: {
		nombre: 'Galicia',
		isla: 'Gran Canaria',
	},
	149: {
		nombre: 'Triana Mayor',
		isla: 'Gran Canaria',
	},
	177: {
		nombre: 'Imeldo',
		isla: 'Tenerife',
	},
	153: {
		nombre: 'C.C. 3 de Mayo',
		isla: 'Tenerife',
	},
	146: {
		nombre: 'C.C. Plaza',
		isla: 'Tenerife',
	},
	271: {
		nombre: 'Duke',
		isla: 'Tenerife',
	},
	181: {
		nombre: 'Siam Mall',
		isla: 'Tenerife',
	},
	180: {
		nombre: 'Casino',
		isla: 'Lanzarote',
	},
	90: {
		nombre: 'Rubicon',
		isla: 'Lanzarote',
	},
	182: {
		nombre: 'Corralejo',
		isla: 'Fuerteventura',
	},
	173: {
		nombre: 'Store Jandia',
		isla: 'Fuerteventura',
	},
};
