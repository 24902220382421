import {
	BLOQUEAR_PEDIDO,
	CANCELAR_PEDIDO_TIENDA,
	DESBLOQUEAR_PEDIDO,
	ELIMINAR_TRASPASO,
	SET_PREPARACION,
	UPDATE_PEDIDOS,
} from '../actionTypes';

const initialState = {
	fetching: false,
	ultimaActualizacion: null,
	pedidos: [],
};

const Pedidos = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_PEDIDOS:
			return {
				...state,
				...payload,
				pedidos: payload.pedidos ? payload.pedidos : state.pedidos,
			};

		case SET_PREPARACION:
			const pedidoPreparacion = state.pedidos.find((p) => p.ID === payload.ID);
			if (pedidoPreparacion) {
				pedidoPreparacion.PICKING = payload.PICKING;
			}
			return {
				...state,
				pedidos: state.pedidos,
			};
		case BLOQUEAR_PEDIDO:
			const pedidoBloqueo = state.pedidos.find((p) => p.ID === payload.ID);
			if (pedidoBloqueo) {
				pedidoBloqueo.LOCKED = true;
				pedidoBloqueo.LOCK = payload.LOCK;
			}
			return {
				...state,
				pedidos: state.pedidos,
			};
		case DESBLOQUEAR_PEDIDO:
			const pedidoDesbloqueo = state.pedidos.find((p) => p.ID === payload.ID);
			if (pedidoDesbloqueo) {
				pedidoDesbloqueo.LOCKED = false;
				pedidoDesbloqueo.LOCK = null;
			}
			return {
				...state,
				pedidos: state.pedidos,
			};

		case CANCELAR_PEDIDO_TIENDA:
			const pedidoCancelarPedidosTienda = state.pedidos.find(
				(p) => p.ID === payload.ORDER_ID
			);
			if (pedidoCancelarPedidosTienda) {
				const pedidoTienda = pedidoCancelarPedidosTienda.REQUESTS.find(
					(pT) => pT.ID === payload.ID
				);
				if (pedidoTienda) {
					pedidoTienda.CANCELED_UNIXDATE = payload.CANCELED_UNIXDATE;
				}
			}
			return {
				...state,
				pedidos: state.pedidos,
			};
		case ELIMINAR_TRASPASO:
			const pedidoEliminarTraspaso = state.pedidos.find(
				(p) => p.ID === payload.ORDER_ID
			);
			if (pedidoEliminarTraspaso) {
				pedidoEliminarTraspaso.traspasos =
					pedidoEliminarTraspaso.traspasos.filter((pT) => pT.ID !== payload.ID);
			}
			return {
				...state,
				pedidos: state.pedidos,
			};
		default:
			return {
				...state,
			};
	}
};

export default Pedidos;
