import { UPDATE_NOTIFICATIONS } from '../actionTypes';

const initialState = {
	fetching: false,
	ultimaActualizacion: null,
	notifications: [],
};

const Notifications = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_NOTIFICATIONS:
			return {
				...state,
				...payload,
				notifications: payload.notifications
					? payload.notifications.map((f) => ({ id: f.id, ...f }))
					: state.notifications,
			};
		default:
			return {
				...state,
			};
	}
};

export default Notifications;
