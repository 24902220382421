import React, { useCallback, useEffect, useState } from 'react';
import Contenido from '../../../layout/Contenido';
import { useSelector } from 'react-redux';
import { blue } from '@material-ui/core/colors';
import { DataGrid } from '@material-ui/data-grid';
import { esProductoCrossSelling, getPedidosIncompletos } from '../../../utils';
import ListPdfDownloadButton from '../../botones/ListPdfDownloadButton';
import moment from 'moment';
import { getProductsPlacementsApi } from '../../../api/common.services';

export default function CrossSelling() {
	const { user } = useSelector((content) => content.User);
	const { pedidos } = useSelector((content) => content.Pedidos);
	const [crossSellingProducts, setCrossSellingProducts] = useState([]);

	const getCrossSellingProducts = useCallback(() => {
		const pedidosIncompletos = getPedidosIncompletos(pedidos);
		const productosCrossSelling = {};
		pedidosIncompletos.forEach((pedido) => {
			pedido.DETAILS.forEach((producto) => {
				if (esProductoCrossSelling(producto)) {
					const { PRODUCT_ID, DESCRIPTION, INCOMPLETE_AMOUNT } = producto;
					productosCrossSelling[PRODUCT_ID] = {
						id: PRODUCT_ID,
						PRODUCT_ID,
						name: DESCRIPTION,
						amount:
							INCOMPLETE_AMOUNT +
							(productosCrossSelling[PRODUCT_ID]?.amount || 0),
					};
				}
			});
		});
		return Object.values(productosCrossSelling);
	}, [pedidos]);

	useEffect(() => {
		if (pedidos.length === 0) {
			setCrossSellingProducts([]);
		} else {
			const products = getCrossSellingProducts();
			getProductsPlacementsApi({
				productIds: products.map((p) => p.id),
				codAlmacen: user.equipo === 'PERFUMERIA' ? '14' : '1',
			}).then(({ data }) => {
				const productPlacements = data;
				products.forEach((product) => {
					product.placement = productPlacements[product.id] || '';
				});
				setCrossSellingProducts([...products]);
			});
		}
	}, [pedidos, getCrossSellingProducts, user.equipo]);

	const columns = [
		{
			sortable: false,
			field: 'PRODUCT_ID',
			headerName: 'Código',
			width: 100,
		},
		{
			sortable: false,
			field: 'name',
			headerName: 'Producto',
			flex: 1,
		},
		{
			sortable: false,
			field: 'amount',
			headerName: '#',
			width: 50,
			renderCell: ({ value: amount }) => 'x' + amount,
		},
		{
			sortable: false,
			field: 'placement',
			headerName: 'Ubicacion',
			width: 200,
		},
	];

	const productsToPdfMapper = (data) => {
		return data.map((product) => [
			product.id,
			product.name,
			product.amount,
			product.placement,
		]);
	};

	return (
		<Contenido
			title='Cross Selling'
			color={blue[700]}
			acciones={
				<ListPdfDownloadButton
					titulo={'Crosselling Products'}
					subtitulo={moment().format('DD-MM-YYYY HH:mm')}
					cabeceras={['Código', 'Producto', 'Cantidad', 'Ubicacion']}
					datos={productsToPdfMapper(crossSellingProducts)}
				/>
			}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				<DataGrid
					rows={crossSellingProducts}
					columns={columns}
					pageSize={100}
					disableColumnMenu
					disableSelectionOnClick
				/>
			</div>
		</Contenido>
	);
}
