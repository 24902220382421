import axios from 'axios';
import { openToPrint } from '../utils';

/** Pedidos */

export const fetchPedidosApi = () => {
	// return axios.get(`/api/pedidos`);
	return axios.get(`/api/v2/activeorders`);
};

export const fetchPreparacionesApi = ({ orderIds }) => {
	return axios.get(`/api/preparaciones`, {
		params: { orderIds: orderIds.join(',') },
	});
};

export const fetchParalizadosApi = () => {
	// return axios.get(`/api/paralizados`);
	return axios.get(`/api/v2/lockedorders`);
};

export const fetchBloquearPedidoApi = ({ ID }) => {
	return axios.delete(`/api/pedidos/${ID}`);
};
export const fetchDesbloquearPedidoApi = ({ ID }) => {
	return axios.put(`/api/pedidos/${ID}`);
};

/** Pedidos a tienda */

export const fetchPedidoTiendaApi = ({ CODALMACEN, CODIGOFECHA }) => {
	if (typeof CODIGOFECHA === 'undefined') {
		return axios.get(`/api/pedidosTienda?CODALMACEN=${CODALMACEN}`);
	} else {
		return axios.get(
			`/api/pedidosTienda?CODALMACEN=${CODALMACEN}&CODIGOFECHA=${CODIGOFECHA}`
		);
	}
};

export const fetchCancelarPedidoTiendaApi = ({ ID }) => {
	return axios.delete(`/api/pedidosTienda/${ID}`);
};

export const fetchEnviarPedidoTiendaApi = ({
	CODALMACEN,
	CODIGOFECHA,
	insertedCodes,
}) => {
	return axios.post(
		`/api/pedidosTienda?CODALMACEN=${CODALMACEN}&CODIGOFECHA=${CODIGOFECHA}`,
		JSON.stringify({ shipping: insertedCodes })
	);
};

/** Preparaciones */

export const fetchPrepararPedidoApi = ({ ID }) => {
	return axios.put(`/api/preparaciones/${ID}`);
};

export const fetchCancelarPreparacionPedidoApi = ({ ID }) => {
	return axios.delete(`/api/preparaciones/${ID}`);
};

/** Facturas */

export const fetchFacturasApi = () => {
	return axios.get(`/api/facturas`);
};

export const fetchNuevaFacturaApi = ({ SUPEDIDO, NUMFAC }) => {
	return axios.put(
		`/api/facturas`,
		JSON.stringify({
			SUPEDIDO,
			NUMFAC,
		})
	);
};

export const fetchEliminarFacturaApi = ({ SUPEDIDO }) => {
	return axios.delete(`/api/facturas/${SUPEDIDO}`);
};

/** Traspasos */

export const fetchEliminarTraspasoApi = ({ ID }) => {
	return axios.delete(`/api/traspasos/${ID}`);
};

/** Notifications */

export const fetchNotificationsApi = () => {
	return axios.get(`/api/notifications`);
};

export const setNotificationReadApi = (ID_NOTIFICATION) => {
	return axios.put(`/api/notifications/${ID_NOTIFICATION}`);
};

export const prepararPedidoGrupal = async ({ orders, products }) => {
	const blobData = await axios
		.post(
			'/api/preparaciones-grupal',
			{
				orders,
				products,
			},
			{
				responseType: 'blob',
				withCredentials: true,
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.error('Error generando pdf', error);
			return null;
		});

	if (blobData) {
		const url = window.URL.createObjectURL(
			new Blob([blobData], { type: 'application/pdf' })
		);
		await openToPrint({ url });
		return true;
	}

	return false;
};

export const getProductsPlacementsApi = async ({ productIds, codAlmacen }) => {
	return axios.get(`/api/products/ubicacion`, {
		params: { productIds: productIds.join(','), codAlmacen },
	});
};
