import { UPDATE_FACTURAS } from '../actionTypes';

const initialState = {
	fetching: false,
	ultimaActualizacion: null,
	facturas: [],
};

const Facturas = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_FACTURAS:
			return {
				...state,
				...payload,
				facturas: payload.facturas
					? payload.facturas.map((f) => ({ id: f.ID, ...f }))
					: state.facturas,
			};
		default:
			return {
				...state,
			};
	}
};

export default Facturas;
