import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import App from './App';
import store from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Login from './components/application/auth/login';
import Almacen from './components/application/almacen/almacen';
import { routes } from './route';

const parseJwt = (token) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

const Root = (props) => {
	if (
		process.env.NODE_ENV === 'development' &&
		typeof process.env.ENABLE_MOCKS !== 'undefined' &&
		process.env.ENABLE_MOCKS === 'true'
	) {
		require('./api/mocks');
	}

	const token = localStorage.getItem('token');
	if (token) {
		const decodedJwt = parseJwt(token);
		if (decodedJwt.exp * 1000 >= Date.now()) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		}
	}

	axios.defaults.headers['Content-Type'] = 'application/json';
	axios.defaults.responseType = 'json';
	axios.defaults.withCredentials = true;

	axios.interceptors.response.use(
		({ data = {} }) => {
			if (data.success !== undefined) {
				return data;
			}
			return {
				success: true,
				data,
			};
		},
		(error) => {
			const { data, status, ...restResponse } = error.response || {
				status: 500,
				data: { success: false },
			};

			let apiResponse = {
				success: false,
				errors: [],
			};
			if (data && data.success !== undefined) {
				apiResponse = data;
			}
			return {
				...restResponse,
				status,
				...apiResponse,
			};
		}
	);

	if (process.env.NODE_ENV === 'development') {
		axios.defaults.baseURL = process.env.REACT_APP_DEV_API_URL;
	} else {
		axios.defaults.baseURL = process.env.PUBLIC_URL;
	}

	return (
		<Provider store={store}>
			<BrowserRouter basename={`/`}>
				<Switch>
					<Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
					<Route
						path={`${process.env.PUBLIC_URL}/almacen/:CODALMACEN/:CODIGOFECHA`}
						component={Almacen}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/almacen/:CODALMACEN/`}
						component={Almacen}
					/>
					<App>
						<TransitionGroup id='TransitionGroup'>
							{routes.map(({ path, Component }) => (
								<Route
									key={path}
									exact
									path={`${process.env.PUBLIC_URL}${path}`}>
									{({ match }) => (
										<CSSTransition
											in={match != null}
											timeout={100}
											unmountOnExit>
											<Component />
										</CSSTransition>
									)}
								</Route>
							))}
						</TransitionGroup>
					</App>
				</Switch>
			</BrowserRouter>
		</Provider>
	);
};

ReactDOM.render(<Root />, document.getElementById('root'));
