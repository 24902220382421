import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import './App.css';
import { Redirect, withRouter } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
	BLOQUEAR_PEDIDO,
	DESBLOQUEAR_PEDIDO,
	SET_PREPARACION,
	WATCH_NOTIFICATIONS,
	WATCH_PARALIZADOS,
	WATCH_PEDIDOS,
	WATCH_USER,
} from './redux/actionTypes';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from './layout/sidebar';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import { setUserOnline } from './redux/user/action';
import { setStatus } from './redux/status/status.action';
import DbStatusOverlay from './components/DbStatusOverlay';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100%',
	},
}));

function App({ children }) {
	const dispatch = useDispatch();

	axios.interceptors.response.use((response) => {
		const { config, status } = response;

		// Si se caduca el token recargamos la web
		if (status === 401 && config.url !== '/api/me') {
			dispatch(setUserOnline(false));

			// eslint-disable-next-line no-restricted-globals
			setTimeout(() => location.reload(), 5000);
		}
		return response;
	});

	const {
		fetching: fetchingUser,
		user,
		online,
	} = useSelector((content) => content.User);
	const {
		fetching: fetchingPedidos,
		ultimaActualizacion: ultimaActualizacionPedidos,
	} = useSelector((content) => content.Pedidos);
	const {
		fetching: fetchingNotifications,
		ultimaActualizacion: ultimaActualizacionNotifications,
	} = useSelector((content) => content.Notifications);

	/** ***********************************************************************
	 *  Socket IO
	 */

	const activarSocket = useCallback(() => {
		if (!user) {
			return;
		}

		const socket = socketIOClient(axios.defaults.baseURL, {
			query: {
				userId: user.id,
			},
		});

		socket.on('connect', () => {
			console.log('Conectado');
			dispatch(setUserOnline(true));
		});
		socket.on('disconnect', () => {
			dispatch(setUserOnline(false));
		});

		socket.on('dbSabinaStatus', ({ status }) => {
			dispatch(
				setStatus({
					dbSabinaStatus: status,
				})
			);
		});

		socket.on('preparation', ({ id, preparation }) => {
			dispatch({
				type: SET_PREPARACION,
				payload: {
					ID: id,
					PICKING: preparation,
				},
			});
		});

		socket.on('lock', ({ id, lock }) => {
			if (lock) {
				dispatch({
					type: BLOQUEAR_PEDIDO,
					payload: {
						ID: id,
						LOCK: lock,
					},
				});
			} else {
				dispatch({
					type: DESBLOQUEAR_PEDIDO,
					payload: { ID: id },
				});
			}
		});

		/* newSocket.on('new_notification', (notif) => {
      console.log('notification: ',notif);
    }); */

		return () => {
			socket.disconnect();
			socket.close();
		};
	}, [user, dispatch]);
	useEffect(() => activarSocket(), [activarSocket]);

	/** *********************************************************************** */

	useEffect(() => {
		dispatch({ type: WATCH_USER });
	}, [dispatch]);

	useEffect(() => {
		if (user) {
			dispatch({ type: WATCH_PEDIDOS });
			dispatch({ type: WATCH_PARALIZADOS });
			dispatch({ type: WATCH_NOTIFICATIONS });
		}
	}, [dispatch, user]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (user) {
				if (!fetchingPedidos && ultimaActualizacionPedidos) {
					const segundosTranscurridos =
						moment().unix() - ultimaActualizacionPedidos.unix();
					if (segundosTranscurridos >= 30) {
						dispatch({ type: WATCH_PEDIDOS });
					}
				}
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [dispatch, user, ultimaActualizacionPedidos, fetchingPedidos]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (user) {
				if (!fetchingNotifications && ultimaActualizacionNotifications) {
					const segundosTranscurridos =
						moment().unix() - ultimaActualizacionNotifications.unix();
					if (segundosTranscurridos >= 30) {
						dispatch({ type: WATCH_NOTIFICATIONS });
					}
				}
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [dispatch, user, ultimaActualizacionNotifications, fetchingNotifications]);

	const recargarWeb = () => {
		// eslint-disable-next-line no-restricted-globals
		location.reload();
	};

	const classes = useStyles();

	return (
		<>
			{!fetchingUser && user && (
				<div className={classes.root}>
					<CssBaseline />
					<Sidebar />
					{children}
				</div>
			)}

			{!fetchingUser && !user && (
				<>
					Ir al login
					<Redirect to={`${process.env.PUBLIC_URL}/login`} />
				</>
			)}

			<Dialog
				open={!online}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>Tu sesión a caducado</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Para seguir operando debes volver a conectarte
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button component='a' color='primary' autoFocus onClick={recargarWeb}>
						Volver a conectarme
					</Button>
				</DialogActions>
			</Dialog>

			<DbStatusOverlay />
		</>
	);
}

export default withRouter(App);
