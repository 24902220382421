import Contenido from '../../../layout/Contenido';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ListItemSecondaryAction, Typography, Switch } from '@material-ui/core';
import List from '@material-ui/core/List';
import { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SettingsService } from '../../../api/settings.services';

export default function SettingsPage() {
	const [settings, setSettings] = useState(null);

	const getSettings = () => {
		return SettingsService.getSettingsService();
	};

	const setValue = async (id, value) => {
		const updatedSettings = await SettingsService.updateSettingsParamService({
			id,
			value,
		});
		if (updatedSettings) {
			return setSettings(updatedSettings);
		}
		return getSettings().then(setSettings);
	};

	useEffect(() => {
		getSettings().then(setSettings);
	}, []);

	return (
		<Contenido title='Settings'>
			<Card style={{ margin: '1rem' }}>
				<CardContent>
					<Typography gutterBottom variant='h5' component='h5'>
						Settings Params
					</Typography>
					<List>
						{settings &&
							settings.params.map((settingsParam) => (
								<ListItem
									alignItems='flex-start'
									key={`setting_param_${settingsParam.id}`}>
									<ListItemText
										primary={settingsParam.name}
										secondary={settingsParam.description}
									/>
									{settingsParam.type === 'BOOLEAN' && (
										<ListItemSecondaryAction>
											<Switch
												color={'primary'}
												onChange={(event, checked) =>
													setValue(settingsParam.id, checked ? 'true' : 'false')
												}
												checked={settingsParam.value === 'true'}
											/>
										</ListItemSecondaryAction>
									)}
								</ListItem>
							))}
					</List>
				</CardContent>
			</Card>
		</Contenido>
	);
}
