import { UPDATE_PARALIZADOS } from '../actionTypes';

const initialState = {
	fetching: false,
	ultimaActualizacion: null,
	paralizados: [],
};

const Paralizados = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_PARALIZADOS:
			return {
				...state,
				...payload,
				paralizados: payload.paralizados
					? payload.paralizados
					: state.paralizados,
			};
		default:
			return {
				...state,
			};
	}
};

export default Paralizados;
