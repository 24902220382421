import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchFacturasApi } from '../../api/common.services';
import { WATCH_FACTURAS } from '../actionTypes';
import { updateFacturas } from './action';
import moment from 'moment';

function* fetchFacturasAsyn() {
	yield put(
		updateFacturas({
			fetching: true,
		})
	);
	let { success, data } = yield call(fetchFacturasApi);
	yield put(
		updateFacturas({
			fetching: false,
			ultimaActualizacion: moment(),
			facturas: success ? data : [],
		})
	);
}

export function* watcherFacturas() {
	yield takeLatest(WATCH_FACTURAS, fetchFacturasAsyn);
}
