import React from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

export default function DownloadButton({
	children = 'Descargar',
	url = '',
	fileName = '',
	...buttonAttrs
}) {
	buttonAttrs = {
		color: 'primary',
		variant: 'contained',
		startIcon: <GetAppIcon />,
		...buttonAttrs,
	};
	return (
		<Button href={url} target={'_blank'} {...buttonAttrs} download={fileName}>
			{children}
		</Button>
	);
}
