import React, { useEffect, useState } from 'react';
import { getDataUrlImagenTarjetaRegalo } from '../../utils';
import PrintButton from './PrintButton';
import DownloadButton from './DownloadButton';

export default function GiftCardActionButton({
	type = 'download',
	message = '',
	...buttonAttrs
}) {
	const isValidMessage = message && message !== '';
	const [loading, setLoading] = useState(false);
	const [cardUrl, setCardUrl] = useState('');

	useEffect(() => {
		if (isValidMessage) {
			setLoading(true);
			getDataUrlImagenTarjetaRegalo({ texto: message }).then((value) => {
				setCardUrl(value);
				setLoading(false);
			});
		}
	}, [message, isValidMessage]);

	if (type === 'print') {
		return (
			<PrintButton
				url={cardUrl}
				disabled={!isValidMessage || loading}
				{...buttonAttrs}
			/>
		);
	}

	return (
		<DownloadButton
			url={cardUrl}
			fileName={'imagenTarjetaRegalo.jpeg'}
			disabled={!isValidMessage || loading}
		/>
	);
}
