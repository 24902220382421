import React from 'react';
import Contenido from '../../../layout/Contenido';
import { useDispatch, useSelector } from 'react-redux';
import { blue } from '@material-ui/core/colors';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { setNotificationReadApi } from '../../../api/common.services';

import { WATCH_NOTIFICATIONS } from '../../../redux/actionTypes';

export default function Notifications() {
	let read_notifications = [];

	const dispatch = useDispatch();

	const { fetching, notifications } = useSelector(
		(content) => content.Notifications
	);

	const setRead = async (notification) => {
		let id = notification.id;
		read_notifications[id] = true;

		let res = await setNotificationReadApi(id);

		if (res.success) {
			dispatch({ type: WATCH_NOTIFICATIONS });
		}
	};

	const columns = [
		{
			sortable: false,
			field: 'id',
			headerName: 'ID',
			width: 200,
			//renderCell: ({ row: notification }) => (<>{row</>)
		},
		{
			sortable: false,
			field: 'text',
			headerName: 'Texto',
			flex: 1,
			//renderCell: ({ row: notification }) => (<>{row</>)
		},
		{
			sortable: true,
			field: 'updatedAt',
			headerName: 'Actualizado',
			width: 210,
			renderCell: ({ value: dateStr }) =>
				moment(dateStr).format('DD/MM/YYYY HH:mm'),
		},
		{
			sortable: true,
			field: 'createdAt',
			headerName: 'Creado',
			width: 210,
			renderCell: ({ value: dateStr }) =>
				moment(dateStr).format('DD/MM/YYYY HH:mm'),
		},
		{
			sortable: true,
			field: 'repeated',
			headerName: 'Repeticiones',
			width: 150,
			//renderCell: ({ row: notification }) => (<>{row</>)
		},
		{
			sortable: false,
			field: 'action-read',
			headerName: 'Leer',
			width: 180,
			//hide: (!showBloquear || !esAdmin),
			renderCell: ({ row: notification }) => {
				return (
					<Button
						variant='contained'
						color='primary'
						disabled={
							typeof read_notifications[notification.id] !== 'undefined'
						}
						onClick={() => setRead(notification)}>
						Leido
					</Button>
				);
			},
		},
	];

	return (
		<Contenido title='Notificaciones' color={blue[700]}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				{/* <pre>{JSON.stringify(notifications, null, 4)}</pre> */}
				<DataGrid
					rows={notifications}
					columns={columns}
					pageSize={100}
					loading={fetching}
					disableColumnMenu
					disableSelectionOnClick
					/* components={{
            Pagination: CustomPagination
          }} */
				/>
			</div>
		</Contenido>
	);
}
