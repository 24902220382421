import axios from 'axios';

export const SettingsService = {
	getSettingsService: async () => {
		const { success, data } = await axios.get(`/api/settings`);
		if (success) {
			return data.settings;
		}
		return null;
	},

	updateSettingsParamService: async ({ id, value }) => {
		const { success, data } = await axios.put(`/api/settings-params/${id}`, {
			value,
		});
		if (success) {
			return data.settings;
		}
		return null;
	},
};
