import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchNotificationsApi } from '../../api/common.services';
import { WATCH_NOTIFICATIONS } from '../actionTypes';
import { updateNotifications } from './action';
import moment from 'moment';

function* fetchNotificationsAsyn() {
	yield put(
		updateNotifications({
			fetching: true,
		})
	);
	let { success, data } = yield call(fetchNotificationsApi);
	yield put(
		updateNotifications({
			fetching: false,
			ultimaActualizacion: moment(),
			notifications: success ? data : [],
		})
	);
}

export function* watcherNotifications() {
	yield takeLatest(WATCH_NOTIFICATIONS, fetchNotificationsAsyn);
}
