import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogoConfirmacion({
	textButtonOpen = 'Confirmar',
	title = '',
	text = null,
	buttonProps = { variant: 'contained' },
	buttonConfirmarProps = { color: 'primary' },
	textButtonConfirmar = 'Confirmar',
	textButtonCancelar = 'Cancelar',
	handleConfirmar = () => null,
	children,
}) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirm = () => {
		handleClose();
		handleConfirmar();
	};

	return (
		<div>
			<Button {...buttonProps} onClick={handleClickOpen}>
				{textButtonOpen}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				fullWidth>
				<DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
				<DialogContent>
					{text && <DialogContentText>{text}</DialogContentText>}
					{children}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						{textButtonCancelar}
					</Button>
					<Button onClick={handleConfirm} {...buttonConfirmarProps}>
						{textButtonConfirmar}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
