export const CODALMACEN_TRASPASOS_OSABINA = '12';

export const CODARTICULO_PARALIZADO = '393458';
export const CODARTICULO_REGALO = '351667';
export const CODARTICULO_LASER = '421790';

export const ORDER_TYPE_WEB = 'WEB';
export const ORDER_TYPE_TELEPHONIC = 'TELEPHONIC';
export const ORDER_TYPE_FORWARDING = 'FORWARDING';
export const ORDER_TYPE_UNRECOGNIZED = 'UNRECOGNIZED';

export const EMAILS_BLOQUEADOS = ['@2mcgroup.com', '@ecommium.com'];

export const PRECIO_BLOQUEO_PEDIDOS_EEUU = 700;
