import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const styles = (theme) => ({
	complete: {
		margin: 0,
		padding: theme.spacing(2),
		backgroundColor: '#4caf50',
		color: 'white',
	},
	incomplete: {
		margin: 0,
		padding: theme.spacing(2),
		backgroundColor: '#ff9800',
		color: 'white',
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, complete = false, ...other } = props;
	return (
		<MuiDialogTitle
			disableTypography
			className={complete ? classes.complete : classes.incomplete}
			{...other}>
			<Typography variant='h6'>{children}</Typography>
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function FinalizeDialog({
	open = false,
	handleSend = () => null,
	handleClose = () => null,
	resume: { isComplete, pedidos, insertedCodes },
}) {
	return (
		<Dialog fullWidth open={open}>
			<DialogTitle complete={isComplete}>
				Pedido {isComplete ? 'completo' : 'incompleto'}
			</DialogTitle>
			<DialogContent dividers>
				<Box
					style={{
						paddingRight: '2rem',
						paddingBottom: '2rem',
					}}>
					{pedidos.map((p, i) => {
						const insertedAmount = insertedCodes[p.ID] || 0;
						const incompleteAmount = p.CANTIDAD - insertedAmount;
						return (
							<Typography
								key={i}
								gutterBottom
								color={incompleteAmount === 0 ? 'textPrimary' : 'secondary'}
								component={'div'}>
								{incompleteAmount === 0 && (
									<Chip
										label={'Completo'}
										variant={'default'}
										size={'small'}
										color={'primary'}
										icon={<DoneIcon />}
										style={{ marginRight: '0.5rem' }}
									/>
								)}
								{incompleteAmount > 0 && (
									<Chip
										label={`Faltan ${incompleteAmount}`}
										variant={'default'}
										size={'small'}
										color={'secondary'}
										style={{ marginRight: '0.5rem' }}
									/>
								)}
								{p.CANTIDAD}x {p.DESCRIPCION}
							</Typography>
						);
					})}
				</Box>
			</DialogContent>
			<DialogActions>
				{!isComplete && (
					<Button
						autoFocus
						onClick={handleClose}
						color={'primary'}
						variant={'outlined'}>
						Cancelar
					</Button>
				)}

				<Button
					onClick={handleSend}
					color={'primary'}
					variant={isComplete ? 'contained' : 'outlined'}>
					Finalizar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
