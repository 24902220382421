import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { DataGrid, useGridSlotComponentProps } from '@material-ui/data-grid';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import IconoRegalo from '../iconos/IconoRegalo';
import IconoLaser from '../iconos/IconoLaser';
import LinkDetallesPedido from '../LinkDetallesPedido';
import IconoPedidosTienda from '../iconos/IconoPedidosTienda';
import Button from '@material-ui/core/Button';
import {
	countryToFlag,
	esPedidoBloqueado,
	esPedidoCompleto,
	esPedidoEmailBloqueado,
	esPedidoEnPreparacion,
	esPedidoEstadosUnidos,
	esPedidoInglaterra,
	esPedidoMarketplace,
	esPedidoParalizado,
	getPedidosATiendaActivos,
	getPedidosATiendaFuturos,
	getTraspasosActivos,
	getTraspasosFuturos,
	tieneProductoCrossSelling,
	tieneProductoSinStock,
} from '../../utils';
import BotonBloquear from '../BotonBloquear';
import {
	fetchCancelarPreparacionPedidoApi,
	fetchDesbloquearPedidoApi,
	fetchPrepararPedidoApi,
} from '../../api/common.services';
import { useDispatch, useSelector } from 'react-redux';
import { DESBLOQUEAR_PEDIDO, SET_PREPARACION } from '../../redux/actionTypes';
import EtiquetaEstadoPedido from '../EtiquetaEstadoPedido';
import IconoSinStock from '../iconos/IconoSinStock';
import DialogoDetallesPrepararPedido from '../DialogoDetallesPrepararPedido';
import LinkDetallesPrepararPedido from '../LinkDetallesPrepararPedido';

import Pagination from '@material-ui/lab/Pagination';
import IconFutureRequests from '../iconos/IconFutureRequests';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { Box } from '@material-ui/core';
import { PRECIO_BLOQUEO_PEDIDOS_EEUU } from '../../constants';
import CarrierListImage from '../carrier/CarrierListImage';
import MarketPlaceImage from '../marketplace/MarketPlaceImage';

function CustomPagination() {
	const { state, apiRef } = useGridSlotComponentProps();

	return (
		<Pagination
			style={{ display: 'flex' }}
			color='primary'
			count={state.pagination.pageCount}
			page={state.pagination.page + 1}
			onChange={(event, value) => apiRef.current.setPage(value - 1)}
		/>
	);
}

export default function TablaPedidos({
	loading = false,
	pedidos = [],
	showPreparar = false,
	showBloquear = false,
	showTraspasos = false,
	showPedidosATienda = false,
}) {
	const dispatch = useDispatch();

	const { esAdmin, showTraspasos: showTraspasosEquipo } = useSelector(
		(content) => content.User
	);
	const [pedidoMostrarDetallesPreparar, setPedidoMostrarDetallesPreparar] =
		useState(null);
	const [pedidosOrdenados, setPedidosOrdenados] = useState([]);

	const desbloquearPedido = async (pedido) => {
		const { ID } = pedido;
		const { success } = await fetchDesbloquearPedidoApi({ ID });
		if (success) {
			dispatch({
				type: DESBLOQUEAR_PEDIDO,
				payload: { ID },
			});
		}
	};

	const prepararPedido = async (pedido) => {
		setPedidoMostrarDetallesPreparar(pedido);
		const { ID } = pedido;
		dispatch({
			type: SET_PREPARACION,
			payload: {
				ID,
				PICKING: true,
			},
		});
		await fetchPrepararPedidoApi({ ID });
	};

	const detenerPreparacionPedido = async (pedido) => {
		const { ID } = pedido;
		dispatch({
			type: SET_PREPARACION,
			payload: {
				ID,
				PICKING: false,
			},
		});
		await fetchCancelarPreparacionPedidoApi({ ID });
	};

	const columns = [
		{
			sortable: false,
			field: 'ID',
			headerName: 'ID',
			width: 120,
			renderCell: ({ row: pedido }) => {
				if (esAdmin) {
					return <LinkDetallesPedido pedido={pedido} />;
				} else {
					return <LinkDetallesPrepararPedido pedido={pedido} />;
				}
			},
		},
		{
			sortable: false,
			field: 'pedidosATienda',
			width: 50,
			hide: !showPedidosATienda,
			renderHeader: () => <LocalShippingIcon />,
			renderCell: ({ row: order }) => {
				if (esPedidoBloqueado(order)) {
					return '';
				}
				if (tieneProductoCrossSelling(order)) {
					return (
						<IconoSinStock
							showRecuerdaPedidosCancelados={order.AMOUNT_SOLD_OUT}
							crossSelling
						/>
					);
				} else if (tieneProductoSinStock(order)) {
					return (
						<IconoSinStock
							showRecuerdaPedidosCancelados={order.AMOUNT_SOLD_OUT}
						/>
					);
				} else if (getPedidosATiendaActivos(order).length > 0) {
					return <IconoPedidosTienda pedido={order} />;
				} else if (getPedidosATiendaFuturos(order).length > 0) {
					return (
						<IconFutureRequests order={order} resumeType={'pedidosTienda'} />
					);
				}
				return '';
			},
		},
		{
			sortable: false,
			field: 'traspasos',
			width: 50,
			hide: !showTraspasos || !showTraspasosEquipo,
			renderHeader: () => <SyncAltIcon />,
			renderCell: ({ row: order }) => {
				if (getTraspasosActivos(order).length > 0) {
					return <SyncAltIcon />;
				}

				if (getTraspasosFuturos(order).length > 0) {
					return <IconFutureRequests order={order} resumeType={'traspasos'} />;
				}

				return '';
			},
		},
		{
			sortable: false,
			field: 'HAS_GIFT_WRAP',
			width: 50,
			renderHeader: () => <CardGiftcardIcon />,

			renderCell: ({ value: HAS_GIFT_WRAP, row: order }) =>
				HAS_GIFT_WRAP ? <IconoRegalo pedido={order} /> : ' ',
		},
		{
			sortable: false,
			field: 'HAS_LASER_ENGRAVINGS',
			headerName: 'Laser',
			width: 50,
			renderHeader: () => <FlashOnIcon />,
			renderCell: ({ value: HAS_LASER_ENGRAVINGS, row: order }) =>
				HAS_LASER_ENGRAVINGS ? <IconoLaser pedido={order} /> : ' ',
		},
		{
			sortable: false,
			field: 'UNIXDATE',
			headerName: 'Fecha',
			width: 150,
			renderCell: ({ value: unixdate }) =>
				moment.unix(unixdate).format('DD/MM/YYYY HH:mm'),
		},

		{
			sortable: false,
			field: 'PAIS',
			headerName: 'Entrega',
			width: 150,
			renderCell: ({ row: order }) => {
				if (esPedidoInglaterra(order)) {
					return (
						<span>
							{countryToFlag('GB')} {order.SHIPMENT.PAIS}
						</span>
					);
				}
				if (esPedidoEstadosUnidos(order)) {
					return (
						<span>
							{countryToFlag('US')} {order.SHIPMENT.PAIS}
						</span>
					);
				}
				return (
					<div>
						<span>{order.SHIPMENT.PAIS}</span>
					</div>
				);
			},
		},

		{
			sortable: false,
			field: 'TRANSPORTE',
			headerName: 'Transporte',
			width: 200,
			renderCell: ({ row: order }) => <CarrierListImage order={order} />,
		},

		{
			sortable: false,
			field: 'NOMBRECOMERCIAL',
			headerName: 'Cliente',
			flex: 1,
			renderCell: ({ row: order }) => {
				const { NOMBRECOMERCIAL } = order.SHIPMENT;
				if (esPedidoMarketplace(order)) {
					return <MarketPlaceImage order={order} />;
				}

				return NOMBRECOMERCIAL;
			},
		},

		{
			sortable: false,
			field: 'TOTAL_PRICE',
			renderHeader: () => <EuroSymbolIcon />,
			width: 120,
			renderCell: ({ value: totalPrice, row: order }) => {
				if (
					esPedidoEstadosUnidos(order) &&
					order.TOTAL >= PRECIO_BLOQUEO_PEDIDOS_EEUU
				) {
					return (
						<Box bgcolor='error.main' color='error.contrastText' p={2}>
							{totalPrice}
						</Box>
					);
				}
				return <Box p={2}>{totalPrice}</Box>;
			},
		},
		{
			sortable: false,
			field: 'DETAILS_RESUME',
			width: 60,
			renderHeader: () => <WidgetsOutlinedIcon />,
			renderCell: ({ value: detailsResume }) => {
				const { ARTICLES, AMOUNT } = detailsResume;
				return (
					<span>
						{ARTICLES}/{AMOUNT}
					</span>
				);
			},
		},
		{
			sortable: false,
			field: 'accion-preparar',
			headerName: 'Preparar',
			width: 150,
			hide: !showPreparar,
			renderCell: ({ row: order }) => {
				if (esPedidoEnPreparacion(order)) {
					if (esAdmin) {
						return (
							<Button
								variant='contained'
								onClick={() => detenerPreparacionPedido(order)}
								color={esPedidoCompleto(order) ? 'default' : 'secondary'}>
								Detener
							</Button>
						);
					}
					return ' ';
				} else {
					if (
						esPedidoEstadosUnidos(order) &&
						order.TOTAL >= PRECIO_BLOQUEO_PEDIDOS_EEUU
					) {
						return (
							<Button variant='outlined' color='primary' disabled>
								Preparar
							</Button>
						);
					}
					return (
						<Button
							variant='contained'
							color='primary'
							onClick={() => prepararPedido(order)}>
							Preparar
						</Button>
					);
				}
			},
		},
		{
			sortable: false,
			field: 'accion-bloquear',
			headerName: 'Bloquear',
			width: 180,
			hide: !showBloquear || !esAdmin,
			renderCell: ({ row: order }) => {
				if (esPedidoParalizado(order)) {
					return ' ';
				}

				if (esPedidoBloqueado(order)) {
					if (esPedidoEmailBloqueado(order)) {
						return (
							<Typography
								style={{
									color: red[700],
									lineHeight: '20px',
									fontSize: '15px',
								}}>
								Usuario bloqueado
								<br />
								Elimine el pedido
							</Typography>
						);
					}
					return (
						<Button
							variant='contained'
							color='primary'
							onClick={() => desbloquearPedido(order)}>
							Desbloquear
						</Button>
					);
				}

				return <BotonBloquear pedido={order} />;
			},
		},
		{
			sortable: false,
			field: 'estado',
			headerName: 'Estado',
			width: 200,
			renderCell: ({ row: order }) => <EtiquetaEstadoPedido pedido={order} />,
		},
	];

	useEffect(() => {
		setPedidosOrdenados(
			pedidos.slice().sort((a, b) => a.UNIXDATE - b.UNIXDATE)
		);
	}, [pedidos]);

	return (
		<>
			<DataGrid
				rows={pedidosOrdenados}
				columns={columns}
				pageSize={100}
				loading={loading}
				disableColumnMenu
				disableSelectionOnClick
				components={{
					Pagination: CustomPagination,
				}}
			/>
			{pedidoMostrarDetallesPreparar && (
				<DialogoDetallesPrepararPedido
					open={pedidoMostrarDetallesPreparar !== null}
					handleClose={() => setPedidoMostrarDetallesPreparar(null)}
					pedido={pedidoMostrarDetallesPreparar}
				/>
			)}
		</>
	);
}
