import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { openToPrint } from '../../utils';
import PrintIcon from '@material-ui/icons/Print';
import { CircularProgress } from '@material-ui/core';

export default function PrintButton({
	children = 'Imprimir',
	url = '',
	...buttonAttrs
}) {
	const [loading, setLoading] = useState(false);

	const onClick = async () => {
		setLoading(true);
		await openToPrint({
			url,
			onLoad: () => setLoading(false),
		});
	};

	buttonAttrs = {
		color: 'primary',
		variant: 'contained',
		startIcon: <PrintIcon />,
		...buttonAttrs,
	};
	const { disabled = false } = buttonAttrs;
	return (
		<Button
			{...buttonAttrs}
			onClick={onClick}
			disabled={loading || disabled}
			endIcon={loading ? <CircularProgress size={'1rem'} /> : null}>
			{children}
		</Button>
	);
}
