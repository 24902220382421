import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {
	esPedidoBloqueado,
	esPedidoCompleto,
	esPedidoGuanxe,
	esPedidoParalizado,
	getPdfInvoiceUrl,
	getPdfLaserUrl,
	getPedidosATiendaActivos,
	getPedidosATiendaFuturos,
	getTraspasosActivos,
	getTraspasosFuturos,
} from '../utils';
import { green, grey, red } from '@material-ui/core/colors';
import { ButtonGroup, Paper } from '@material-ui/core';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { Alert, AlertTitle } from '@material-ui/lab';
import TablaGrabadosLaser from './tablas/TablaGrabadosLaser';
import TablaProductos from './tablas/TablaProductos';
import TablaPedidosTienda from './tablas/TablaPedidosTienda';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import TablaTraspasos from './tablas/TablaTraspasos';
import { useSelector } from 'react-redux';
import PrintButton from './botones/PrintButton';
import GiftCardActionButton from './botones/GiftCardActionButton';
import CarrierListImage from './carrier/CarrierListImage';

const useStyles = makeStyles((theme) => ({
	dialog: {
		backgroundColor: '#f5f5f5',
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},

	contenido: {
		padding: '1rem',
	},

	paper: {
		padding: '1.5rem',
		margin: '1rem',
	},

	paperInfo: {
		padding: '1.5rem',
		margin: '1rem',
		display: 'flex',
		'justify-content': 'space-between',
	},

	especiales: {
		display: 'flex',
		'justify-content': 'center',
	},
	bloquePedidosTraspasos: {
		display: 'flex',
		'justify-content': 'space-between',
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export default function LinkDetallesPedido({ pedido }) {
	const classes = useStyles();

	const { user } = useSelector((content) => content.User);
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		console.log({ pedido });
		setOpen(true);
	};

	const handleClose = () => setOpen(false);

	let estadoPedido = '';
	let color = '';
	switch (true) {
		case esPedidoBloqueado(pedido):
			color = grey[600];
			estadoPedido = 'Pedido bloqueado';
			break;
		case esPedidoParalizado(pedido):
			color = grey[600];
			estadoPedido = 'Pedido paralizado';
			break;
		case esPedidoCompleto(pedido):
			color = green[500];
			estadoPedido = 'Pedido completo';
			break;
		default:
			color = red[600];
			estadoPedido = 'Pedido incompleto';
			break;
	}

	const {
		GIFT_WRAP,
		DATE,
		ID,
		TOTAL_PRICE,
		HAS_LASER_ENGRAVINGS,
		DETAILS_RESUME,
		retraso48h,
	} = pedido;

	const {
		NOMBRECOMERCIAL,
		EMAIL,
		PAIS,
		CODPOSTAL,
		PROVINCIA,
		POBLACION,
		DIRECCION1,
	} = pedido.SHIPMENT;
	const { MENSAJE } = GIFT_WRAP;

	const body = (
		<>
			<AppBar className={classes.appBar} style={{ backgroundColor: color }}>
				<Toolbar>
					<Typography variant='h6' className={classes.title}>
						{estadoPedido}
					</Typography>
					<IconButton
						edge='end'
						autoFocus
						color='inherit'
						onClick={handleClose}
						aria-label='close'>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>

			<div className={classes.contenido}>
				<Paper className={classes.paperInfo}>
					<div>
						<Typography variant='h4'>
							{esPedidoGuanxe(pedido) ? (
								<img
									alt='Guanxe'
									src='/gxe-logo.png'
									style={{
										height: 50,
										marginTop: '0.5rem',
									}}
								/>
							) : (
								NOMBRECOMERCIAL
							)}
						</Typography>
						<Typography variant='body1' gutterBottom>
							{EMAIL}
						</Typography>
						<Typography variant='body2'>
							{DIRECCION1}, {POBLACION}, {CODPOSTAL}, {PROVINCIA}, {PAIS}
						</Typography>
						<CarrierListImage order={pedido} />
					</div>
					<div style={{ textAlign: 'right' }}>
						<Typography variant='h6'>{DATE}</Typography>
						<Typography variant='h4'>{ID}</Typography>
						<Typography variant='body1' gutterBottom>
							Total: {TOTAL_PRICE}
						</Typography>
						<PrintButton url={getPdfInvoiceUrl(pedido.ID, user.equipo)}>
							Imprimir factura
						</PrintButton>
					</div>
				</Paper>

				{(pedido.HAS_GIFT_WRAP || pedido.HAS_LASER_ENGRAVINGS) && (
					<div className={classes.especiales}>
						{pedido.HAS_GIFT_WRAP && (
							<Paper className={classes.paper} style={{ width: 500 }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-start',
									}}>
									<CardGiftcardIcon
										style={{
											fontSize: 40,
											marginRight: '0.5rem',
										}}
									/>
									<h2
										id='simple-modal-title'
										style={{
											marginTop: 7,
											marginBottom: 0,
										}}>
										{' '}
										Envoltorio de Regalo
									</h2>
								</div>

								{MENSAJE !== null && (
									<p>
										{MENSAJE !== '' && (
											<>
												<strong>Mensaje de regalo:</strong>
												<br />
												{MENSAJE}
												<br />
												<ButtonGroup
													color={'primary'}
													aria-label={'outlined primary button group'}
													style={{ marginTop: '1rem' }}>
													<GiftCardActionButton
														message={MENSAJE}
														type={'download'}
														variant={'contained'}
													/>
												</ButtonGroup>
											</>
										)}
										{MENSAJE === '' && (
											<strong style={{ color: red[500] }}>
												Envoltorio de regalo sin mensaje
											</strong>
										)}
									</p>
								)}
								{MENSAJE === null && (
									<strong style={{ color: red[500] }}>
										No se pudo obtener el mensaje para la tarjeta regalo, por
										favor, ponte en contacto con el administrador
									</strong>
								)}
							</Paper>
						)}

						{pedido.HAS_LASER_ENGRAVINGS && (
							<Paper className={classes.paper} style={{ width: 700 }}>
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										marginBottom: '1rem',
									}}>
									<FlashOnIcon
										style={{
											fontSize: 40,
											marginRight: '0.5rem',
										}}
									/>
									<h2
										id='simple-modal-title'
										style={{
											marginTop: 7,
											marginBottom: 0,
										}}>
										{' '}
										Grabado láser
									</h2>
								</div>
								{HAS_LASER_ENGRAVINGS ? (
									<div style={{ marginTop: '1rem' }}>
										<div
											style={{
												height: '200px',
												width: '100%',
											}}>
											<TablaGrabadosLaser pedido={pedido} />
										</div>
										<br />
										<PrintButton
											url={getPdfLaserUrl(pedido.ID)}
											style={{ marginTop: '1rem' }}
										/>
									</div>
								) : (
									<Alert severity='error' style={{ marginTop: '2rem' }}>
										<AlertTitle>Error obteniendo los datos</AlertTitle>
										Contacte con el administrador.
									</Alert>
								)}
							</Paper>
						)}
					</div>
				)}

				<Paper className={classes.paper}>
					<Typography variant='h6' gutterBottom>
						Productos
					</Typography>
					<div style={{ height: DETAILS_RESUME.AMOUNT > 2 ? 300 : 200 }}>
						<TablaProductos pedido={pedido} />
					</div>
				</Paper>

				<div className={classes.bloquePedidosTraspasos}>
					<Paper
						className={classes.paper}
						style={{
							width: '50%',
							marginTop: 0,
						}}>
						<Typography variant='h6' gutterBottom>
							Pedidos a tienda
						</Typography>
						{getPedidosATiendaActivos(pedido).length > 0 ||
						getPedidosATiendaFuturos(pedido).length > 0 ? (
							<div style={{ height: 300 }}>
								<TablaPedidosTienda pedido={pedido} />
							</div>
						) : (
							<Alert severity='info'>
								No se han realizado pedidos a tienda para este pedido
							</Alert>
						)}
					</Paper>

					<Paper
						className={classes.paper}
						style={{
							width: '50%',
							marginTop: 0,
						}}>
						<Typography variant='h6' gutterBottom>
							Traspasos
							{getTraspasosActivos(pedido).length > 0 ||
							getTraspasosFuturos(pedido).length > 0 ? (
								<div style={{ height: 300 }}>
									<TablaTraspasos pedido={pedido} />
								</div>
							) : (
								<Alert severity='info' style={{ marginTop: '1rem' }}>
									No se han realizado traspasos para este pedido
								</Alert>
							)}
						</Typography>
					</Paper>
				</div>
			</div>
		</>
	);

	return (
		<span>
			<Button
				color='primary'
				onClick={handleClickOpen}
				endIcon={retraso48h ? <AccessAlarmIcon /> : ''}>
				{ID}
			</Button>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				className='dialogoDetallesPedido'>
				{body}
			</Dialog>
		</span>
	);
}
