import React from 'react';
import Contenido from '../../../layout/Contenido';
import { useSelector } from 'react-redux';
import TablaPedidos from '../../tablas/TablaPedidos';
import { getPedidosIncompletos } from '../../../utils';
import { red } from '@material-ui/core/colors';

export default function Incompletos() {
	const { fetching, ultimaActualizacion, pedidos } = useSelector(
		(content) => content.Pedidos
	);

	return (
		<Contenido
			title='Pedidos Incompletos'
			color={red[600]}
			showGroupSwitch={false}>
			<div
				style={{
					height: '100%',
					width: '100%',
				}}>
				<TablaPedidos
					loading={fetching && ultimaActualizacion === null}
					pedidos={getPedidosIncompletos(pedidos)}
					showBloquear={true}
					showPreparar={false}
					showTraspasos={true}
					showPedidosATienda={true}
				/>
			</div>
		</Contenido>
	);
}
