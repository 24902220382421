import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

export default function TablaGrabadosLaser({ pedido, CODARTICULO = null }) {
	const columns = [
		{
			sortable: false,
			field: 'id_product',
			headerName: 'ID',
			width: 70,
		},
		{
			sortable: false,
			field: 'DESCRIPTION',
			headerName: 'PRODUCTO',
			width: 200,
		},
		{
			sortable: false,
			field: 'value',
			headerName: 'Grabado',
			flex: 1,
		},
	];

	let laserEngravings = [].concat(
		...pedido.DETAILS.map((d) =>
			d.LASER_ENGRAVINGS.map((l) => ({
				...l,
				DESCRIPTION: d.DESCRIPTION,
			}))
		)
	);
	if (CODARTICULO) {
		laserEngravings = laserEngravings.filter(
			(l) => l.id_product === CODARTICULO
		);
	}

	laserEngravings = laserEngravings.map((l, i) => ({ id: i, ...l }));

	return (
		<DataGrid
			rows={laserEngravings}
			columns={columns}
			pageSize={100}
			hideFooter
			hideFooterPagination
			hideFooterRowCount
			disableColumnMenu
			disableSelectionOnClick
		/>
	);
}
